import React from 'react'
import { LandingHeroImg, Rectangle51 } from '../../../assets'

const SingleBlogs = () => {
    return (
        <div>
            <div className='bg-black text-white px-20 xl:px-52 py-10 font-josefin-sans'>
                <p className='text-[24px] lg:text-[28px] xl:text-[31px] pb-5'>Creative Ways to Make Sex Fun</p>
                <img src={LandingHeroImg} alt='LandingHeroImg' className='w-full h-[329px] lg:h-[629px] object-cover rounded-2xl' />
                <p className='py-5 text-[20] xl:text-[22px] font-light lg:font-normal leading-none'>Sed iaculis leo id nisi laoreet, in elem tum velit fringilla. Phasellus at justo quis orci scelerisque dictum. Sed pharetra nibh vel cursu spellentesque. Morbi odio odio, hendrerit eu sagittis id, malesuada commodo mi. Nullam aliquet elit non dui convallis, ut tincidunt elit lobortis. Curabitur eu tincidunt dui. Suspendisse vestibulum mauris at elit vulputate iaculis. Nam mauris enim, fringilla sed dui sit amet, ultricies blandit tellus.</p>
                <p className='text-[20] xl:text-[22px] font-light lg:font-normal leading-none'>Nulla faucibus elit at eros pellentesque, ornare semper nibh egestas. Vestibulum tristique nunc in placerat consequat. Vivamus
                    condimentum arcu ante, a ornare urna interdum sit amet. Aenean ornare risus nec nisi vulputate vehicula finibus sit amet dui.</p>

                <p className='text-[24px] lg:text-[28px] xl:text-[31px] mt-14 mb-5'>History of Sex</p>
                <p className='text-[20] xl:text-[22px] font-light lg:font-normal leading-none'>Ut sollicitudin ornare neque quis hendrerit. Aenean suscipit eros nec nisi luctus, nec finibus eros ultrices. Nulla facilisi. Integer imperdiet, nisi sed malesuada sodales, urna nunc lobortis erat, sagittis placerat felis magna eu nisi. Morbi scelerisque varius porta. Vestibulum eget ex cursus, dapibus nisi sed, posuere arcu. Donec odio quam, hendrerit sit amet elit non, maximus sagittis velit. Proin ullamcorper ante quis mauris bibendum, sed fringilla massa vestibulum. Nam in sollicitudin est. Pellentesque malesuada auctor viverra. Aenean eleifend augue nibh, eu ornare augue commodo sit amet. Morbi vel sem in elit bibendum consectetur sed vel ligula.</p>

                <img src={Rectangle51} alt='blogListCard' className='w-full h-[329px] lg:h-[605px] object-cover rounded-2xl my-5' />

                <p className='text-[20] xl:text-[22px] font-light lg:font-normal leading-none'>Phasellus eu eros mauris. Proin sem eros, tincidunt quis arcu eget, laoreet tincidunt tellus. Proin sit amet libero et ante ullamcorper porta. Nam ornare porta ligula, eu rutrum massa interdum in. In consequat pellentesque nulla vitae convallis. Donec facilisis, mauris et gravida interdum, diam odio ultrices mi, eu tempor ligula velit vel erat. Cras ut pharetra quam, congue maximus dui. Morbi congue congue dui eget gravida. Sed arcu tellus, euismod a risus eu, viverra dictum sapien.</p>
                <div className='text-[20] xl:text-[22px] font-light lg:font-normal leading-none my-5'>
                    <ul>
                        <li>Vivamus ut efficitur</li>
                        <li>Quisque massa turpis</li>
                        <li>Suspendisse libero</li>
                        <li>Donec tincidunt</li>
                    </ul>
                </div>
                <p className='text-[20] xl:text-[22px] font-light lg:font-normal leading-none'>Sed dui risus, luctus et justo vitae, sagittis lobortis nisl. Sed non nibh at quam dictum porta non vel justo. Nunc erat est, molestie eu nibh eu, facilisis placerat nulla. Praesent eu aliquam nibh. Cras scelerisque, tellus at tincidunt congue, odio risus dignissim leo, id facilisis lectus nisl id ipsum. Vivamus ut elit erat. Vivamus in varius sem, non auctor ex. In hac habitasse platea dictumst.</p>
            </div>

        </div>
    )
}

export default SingleBlogs  