import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputBox from '../../../components/InputBox'
import { imgSelector } from '../../../../assets'
import Dropdown from '../../../components/Dropdown'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const AddSubCategory = () => {
    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/subCategory')
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Sub Category List' btnname='Back to Sub Category List' btnfunction={handleBlog} />
                <InputBox title='Category Title' placholderName='Title' />
                <InputBox title='Category Description' placholderName='Description' />
                <div className='pb-6 flex flex-col'>
                    <label className='text-zinc-500 font-semibold text-[22px]'>Main Category</label>
                    <Dropdown />
                </div>                <div className='pb-6 flex flex-col'>
                    <label className='text-zinc-500 pb-3 font-semibold text-[22px]'>Category Image</label>
                    <div className="flex items-center justify-center w-80">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border border-zinc-300 rounded-lg cursor-pointer bg-white px-2 py-3"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                {selectedImage ? (
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        className="w-full h-32 object-cover mb-4"
                                    />
                                ) : (
                                    <img src={imgSelector} alt="imgSelector" className='w-10 lg:w-20' />
                                )}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                                <p className="mt-6 text-base text-stone-400">
                                    {selectedImage ? 'Change Featured Image' : 'Add Featured Image'}
                                </p>
                            </div>
                        </label>
                    </div>
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 text-lg hover:bg-red-700 font-semibold text-white p-4'>Add Category</button>
            </div>
        </>
    )
}

export default AddSubCategory