import React, { useState } from 'react'
import InputBox from '../../../components/InputBox'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropdown from '../../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { Dote, imgSelector } from '../../../../assets';
import PagenameSearchBox from '../../../components/PagenameSearchBox';

const CreateHelpBlog = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/helpBlog')
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Help Blog List' titlesecond='Create help Blog' imgDote={Dote} btnname='Back to help blog list' btnfunction={handleBlog} />
                <div className='w-full flex gap-6'>
                    <div className='w-[74%]'>
                        <InputBox title='Help Blog Title' placholderName='Blog Title' />
                        <label className='text-zinc-500 font-semibold text-[22px]'>Help Blog Discription</label>
                        <ReactQuill theme="snow" className='h-56 md:mb-20 lg:mb-14 mt-3' />
                        <InputBox title='Meta Title' placholderName='Title' />
                        <InputBox title='Meta Description' placholderName='Description' />
                        <InputBox title='Meta Keywords' placholderName='Keywords' />
                    </div>
                    <div className='w-[25%]'>
                        <div className='pb-6 flex flex-col'>
                            <label className='text-zinc-500 font-semibold text-[20px]'>Categories</label>
                            <Dropdown />
                        </div>
                        <InputBox title='Tags' placholderName='tags' />
                        <div className='pb-6 flex flex-col'>
                            <label className='text-zinc-500 pb-3 font-semibold text-[22px]'>Featured Image</label>
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-64 border border-zinc-300 rounded-lg cursor-pointer bg-white px-2 py-3"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        {selectedImage ? (
                                            <img
                                                src={selectedImage}
                                                alt="Selected"
                                                className="w-full h-32 object-cover mb-4"
                                            />
                                        ) : (
                                            <img src={imgSelector} alt="imgSelector" className='w-10 lg:w-20' />
                                        )}
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            onChange={handleImageChange}
                                        />
                                        <p className="mt-6 text-base text-stone-400">
                                            {selectedImage ? 'Change Featured Image' : 'Add Featured Image'}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Publish Blog</button>
            </div>
        </>
    )
}

export default CreateHelpBlog
