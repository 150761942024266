import React from 'react'
import { useNavigate } from 'react-router-dom/dist'
import Togal from '../../../components/Togal'
import { DeleteImg, eye, rename } from '../../../../assets'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const SubCategory = () => {
    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/addSubCategory')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Sub Category List' placename='Search Category' btnname='Add Category' btnfunction={handleBlog} />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left  text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black  bg-stone-300">
                            <tr>
                                <th scope="col" className=" px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Sub Category
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Main Category
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Published
                                </th>
                                <th scope="col" className=" px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white font-normal text-xl text-black border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap ">ER001</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap "><Togal /></td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                            <tr className="bg-white font-normal text-xl text-black border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap ">ER001</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                                <td className="px-6 py-4 whitespace-nowrap "><Togal /></td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default SubCategory