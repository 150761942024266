import React, { useState } from 'react'
import InputBox from '../../../components/InputBox';
import AddTags from '../../../components/AddTags ';

const Account = () => {

    const dropdownData = [
        {
            label: 'State',
            name: 'Select Your State',
            options: [{ subOpetion: 'option1' }, { subOpetion: 'option2' }, { subOpetion: 'option3' },]
        },
        {
            label: 'Country',
            name: 'Select Your Country',
            options: [{ subOpetion: 'option1' }, { subOpetion: 'option2' }, { subOpetion: 'option3' },]
        }, {
            label: 'Timezone',
            name: 'Select Timezone',
            options: [{ subOpetion: 'option1' }, { subOpetion: 'option2' }, { subOpetion: 'option3' },]
        },
    ]

    const [openIndex, setOpenIndex] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleOptionClick = (menuIndex, option) => {
        setSelectedOptions({ ...selectedOptions, [menuIndex]: option });
        setOpenIndex(null);
    };

    return (
        <>
            <div className='w-full flex justify-between mt-10 font-josefin-sans'>
                <div className='w-[48%]'>
                    <InputBox placholderName='First Name' title='First Name' textType='text' />
                    <InputBox placholderName='Your E-mail' title='E-mail' textType='email' />
                    <InputBox placholderName='Your Address' title='Address' textType='text' />
                    <InputBox placholderName='12456' title='Zip Code' textType='number' />
                    <label className='text-zinc-500 font-semibold text-[22px]'>Language</label>
                    <AddTags />

                </div>
                <div className='w-[48%]'>
                    <InputBox placholderName='Last Name' title='Last Name' textType='text' />
                    <InputBox placholderName='Your Phone Number' title='Phone Number' textType='number' />
                    <div>
                        {dropdownData.map((menu, index) => (
                            <div key={index} className="mb-6">
                                <label className='text-zinc-500 font-semibold text-[22px]'>{menu.label}</label>
                                <div className="relative inline-block text-left w-full mt-3">
                                    <div>
                                        <button
                                            type="button"
                                            className="bg-white w-full text-gray-400 px-2 py-3 border border-zinc-300 rounded-lg"
                                            onClick={() => toggleDropdown(index)}
                                        >
                                            <div className={`flex justify-between text-xl items-center px-5 ${selectedOptions[index] ? 'text-black' : 'text-zinc-300'}`}>
                                                {selectedOptions[index] || 'Select Categories here...'}
                                                <svg
                                                    className="ml-2 h-5 w-5 inline-block"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </button>
                                    </div>

                                    {openIndex === index && (
                                        <div className="absolute w-full z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {menu.options.map((option, optionIndex) => (
                                                    <button
                                                        key={optionIndex}
                                                        className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                                        onClick={() => handleOptionClick(index, option.subOpetion)}
                                                    >
                                                        {option.subOpetion}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save Changes</button>
                </div>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4'>Reset</button>
                </div>
            </div>
        </>
    )
}

export default Account