import React from 'react'
import { img1, img10, img2, img5, img7, img9, Rectangle19, Rectangle47, Rectangle48, singleBlogImg } from '../../assets'
import ImgDetailOnhover from './ImgDetailOnhover'
import { useNavigate } from 'react-router-dom'

const DiscoverImagesBox = () => {

    const navigate = useNavigate()

    const handleImageView = () => {
        navigate('/dashboard/ImageView')
    }

    return (
        <>
            <div className='w-full flex flex-wrap gap-3 justify-center mt-5 lg:mt-14'>
                <div className='flex flex-wrap gap-3 flex-col justify-center'>
                    <div className='flex flex-wrap gap-3 justify-center'>
                        <div className='flex flex-col gap-3 justify-center'>
                            <div className="relative w-[330px] 2xl:w-[370px] h-[450px] 2xl:h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img1}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhover />
                            </div>
                            <div className="relative w-[330px] 2xl:w-[370px] h-[240px] 2xl:h-[265px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img5}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhover />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 justify-center'>
                            <div className="relative w-[330px] 2xl:w-[370px] h-[240px] 2xl:h-[265px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img2}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhover />
                            </div>
                            <div className="relative w-[330px] 2xl:w-[370px] h-[450px] 2xl:h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={Rectangle48}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhover />
                            </div>
                        </div>
                    </div>
                    <div className="relative px-[16px] md:px-0 w-[360px] md:w-[670px] 2xl:w-[750px] h-[244px] hover:cursor-pointer" onClick={handleImageView} >
                        <img
                            src={Rectangle19}
                            alt="Hover Effect"
                            className="h-full w-full object-cover rounded-xl "
                        />
                        <ImgDetailOnhover />
                    </div>
                </div>
                <div className='flex flex-wrap gap-3 justify-center'>
                    <div className='flex flex-col gap-3 justify-center'>
                        <div className="relative w-[330px] 2xl:w-[370px] h-[495px] 2xl:h-[520px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={singleBlogImg}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhover />
                        </div>
                        <div className="relative w-[330px] 2xl:w-[370px] h-[450px] 2xl:h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img7}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhover />
                        </div>
                    </div>
                    <div className='flex flex-col gap-3 justify-center'>
                        <div className="relative w-[330px] 2xl:w-[370px] h-[230px] 2xl:h-[260px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img10}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhover />
                        </div>
                        <div className="relative w-[330px] 2xl:w-[370px] h-[470px] 2xl:h-[520px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={Rectangle47}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhover />
                        </div>
                        <div className="relative w-[330px] 2xl:w-[370px] h-[230px] 2xl:h-[253px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img9}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhover />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiscoverImagesBox
