import React from 'react'
import SelectAIModel from '../../components/SelectAIModel'
import SearchExtension from '../../components/SearchExtension'
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox'
import { goldE, info } from '../../../assets'

const VideoPage = () => {

    const Girl = [
        { name: 'Sequence #1', }, { name: 'Sequence #1', }, { name: 'Sequence #1', },
    ]

    return (
        <>
            <p className='pt-5 text-xl xl:text-[25px] font-medium'>Create videos featuring various sequences. Currently, videos cannot be generated with saved girls.</p>

            <SelectAIModel />

            <SearchExtension />

            <div className='justify-center md:justify-start gap-2 lg:gap-3 items-center flex flex-wrap mt-5'>
                {
                    Girl.map((item, index) => (
                        <div className='w-[200px] border-2 border-stone-700 hover:text-red-600 hover:boreder hover:border-red-600 rounded-full mt-3 hover:cursor-pointer'>
                            <p key={index} className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                        </div>
                    ))
                }
                <div className='w-[300px] border-2 border-stone-700 hover:text-red-600 hover:boreder hover:border-red-600 rounded-2xl mt-3 hover:cursor-pointer'>
                    <div className='flex items-center'>
                        <p className='font-semibold text-2xl py-2 px-4 text-center '>Add sequence 1.00s</p>
                        <div className='bg-white w-fit h-fit rounded-full px-[6px] pt-[2px]'>
                            <p className='text-black text-sm font-semibold'>E</p>
                        </div>
                    </div>
                </div>
            </div>

            <p className='pt-5 text-lg lg:text-2xl'>Each sequence lasts approximately 2 seconds. Sequences can have the same prompt if desired.</p>

            <UseShortWordsInputBox name='Imagine (prompt)' plachold='A beautiful blonde girl, in a lake, smiling, upper body' />
            <UseShortWordsInputBox name='Exclude' plachold='e.g. smiling, sunny weather' />

            <div>
                <div className='flex flex-wrap justify-between pt-20'>
                    <div className='bg-black flex flex-col gap-5 text-white font-josefin-sans'>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Framerate</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <div className='w-[130px] border-2 border-stone-700 hover:text-red-600 hover:boreder hover:border-red-600 rounded-full mt-3 hover:cursor-pointer'>
                                    <p className='font-semibold text-sm md:text-xl py-2 px-4 text-center'>8 FPS</p>
                                </div>
                                <div className='w-[180px] border-2 border-stone-700 hover:text-red-600 hover:boreder hover:border-red-600 rounded-2xl mt-3 hover:cursor-pointer'>
                                    <div className='flex items-center justify-center'>
                                        <p className='font-semibold text-sm md:text-xl py-2 px-4 text-center '>12 FPS  3.50</p>
                                        <div className='bg-white w-fit h-fit rounded-full px-[6px] pt-[2px]'>
                                            <p className='text-black text-sm font-semibold'>E</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:mt-5'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Variations</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <input type="number" className='focus:outline-none bg-zinc-300 rounded-xl px-5 py-1 mt-3 w-52 text-black text-2xl' />
                        </div>
                        <div className='flex items-center gap-5 md:mb-3 lg:mb-0 lg:mt-5'>
                            <input
                                type="radio"
                                className='w-7 h-7'
                            />
                            <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make Private</p>
                        </div>
                    </div>
                    <div className='bg-black flex flex-col gap-4 text-white font-josefin-sans'>
                        <div className='mt-5 lg:mt-0'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Aspect ratio <span className='text-base md:text-lg lg:text-xl xl:text-2xl'>(square, horizontal, vertical)</span></p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 text-center mt-2'>
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-12 text-black text-2xl' />
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-24 text-black text-2xl' />
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-12 text-black text-2xl' />
                            </div>
                        </div>
                    </div>
                </div>
                <button className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
                    Generate for 1.00
                    <img src={goldE} alt="goldE" />
                </button>
            </div>
        </>
    )
}

export default VideoPage