import React from 'react'
import { DeleteImg, eye, rename } from '../../assets'


const CouponTable = () => {
    return (
        <>
            <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                <table className="w-full text-left  text-gray-500 border border-stone-300">
                    <thead className="text-xl text-black  bg-stone-300">
                        <tr>
                            <th scope="col" className=" px-6 py-3">
                                Code
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Coupon Type
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Coupon Amount
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Usage / Limit
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Expiry date
                            </th>
                            <th scope="col" className=" px-6 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b text-xl font-400 text-black border-stone-300">
                            <td className="px-6 py-4 whitespace-nowrap ">ER001</td>
                            <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                            <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                            <td className="px-6 py-4 whitespace-nowrap ">0 / ∞</td>
                            <td className="px-6 py-4 whitespace-nowrap ">May 22, 2024</td>
                            <td className="px-6 py-4">
                                <div className='flex gap-5 justify-center items-center'>
                                    <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white border-b text-xl font-400 text-black border-stone-300">
                            <td className="px-6 py-4 whitespace-nowrap ">ER001</td>
                            <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                            <td className="px-6 py-4 whitespace-nowrap ">Test1 Category</td>
                            <td className="px-6 py-4 whitespace-nowrap ">0 / ∞</td>
                            <td className="px-6 py-4 whitespace-nowrap ">May 22, 2024</td>
                            <td className="px-6 py-4">
                                <div className='flex gap-5 justify-center items-center'>
                                    <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CouponTable