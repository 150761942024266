import React, { useState } from 'react';
import { support } from '../../../assets';
import { CgSearch } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

const Support = () => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('');

    const buttonsData = [
        { name: 'GETTING STARTED', path: '/dashboard/SupportCategory' },
        { name: 'SUBSCRIPTION', path: '' },
        { name: 'CONTENT', path: '' },
        { name: 'ACCOUNT', path: '' },
        { name: 'BUGS', path: '' },
    ];

    const handleButtonClick = (path, name) => {
        if (path) {
            setActiveButton(name);
            navigate(path);
        }
    };

    return (
        <>
            <div className="px-2 lg:px-20 pb-36 bg-black pt-10 text-white">
                <div className='bg-red-800 px-10 2xl:px-36 flex flex-wrap justify-center xl:justify-between items-end rounded-3xl'>
                    <div className='pt-10 md:pt-14 pb-5 xl:pb-24'>
                        <p className='font-semibold md:text-[23px]'>Help Center</p>
                        <p className='font-extrabold text-xl md:text-[33px] mt-2 lg:mt-4'>Find solutions fast.</p>
                        <p className='font-normal md:text-[26px] mt-2 lg:mt-4'>Search hundreds of articles on EROMANTIC.AI Help</p>
                        <div class='bg-white md:py-5 md:ps-14 md:pr-7 p-5 flex justify-between items-center md:w-[640px] rounded-full mt-3 lg:mt-7'>
                            <input type="text" placeholder='Search the Community' class='w-full focus:outline-none text-xl md:text-4xl        md:font-light font-normal text-black placeholder-red-800 font-josefin-sans' />
                            <CgSearch class='text-red-800' size={35} />
                        </div>
                    </div>
                    <div>
                        <img src={support} alt="support" />
                    </div>
                </div>
                <div className='pt-14'>
                    <p className='font-bold text-4xl text-center'>Categories</p>
                    <div className='flex flex-wrap justify-center pt-5 md:pt-16'>
                        {buttonsData.map((item, index) => {
                            const isActive = activeButton === item.name;
                            return (
                                <button
                                    key={index}
                                    onClick={() => handleButtonClick(item.path, item.name)}
                                    className={` md:text-xl font-semibold rounded-full w-52 md:w-[300px] 2xl:w-[390px] h-12 md:h-20 m-2 lg:m-6 ${isActive ? 'bg-red-800 text-white' : 'bg-white text-red-800 hover:bg-red-800 hover:text-white'}`}
                                >
                                    {item.name}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Support;
