import React from 'react'
import { CgSearch } from 'react-icons/cg'
import { safeforwork, singleBlogImg, support } from '../../../assets'
import { useNavigate } from 'react-router-dom'

const SupportCategory = () => {

    const navigate = useNavigate()

    const handleDescription = () => {
        navigate('/dashboard/CategoryDiscription')
    }

    return (
        <>
            <div className="px-2 lg:px-20 pb-36 bg-black pt-10 text-white ">
                <div className='bg-red-800 px-10 2xl:px-36 flex flex-wrap justify-center xl:justify-between items-end rounded-3xl'>
                    <div className='pt-10 lg:pt-24 pb-5 lg:pb-36'>
                        <p className='font-extrabold text-xl md:text-[33px] mt-2 lg:mt-4'>For the Community, by the Community</p>
                        <div class='bg-white md:py-5 md:ps-14 md:pr-7 p-5 flex justify-between items-center md:w-[640px] rounded-full mt-3 md:mt-7'>
                            <input type="text" placeholder='Search the Community' class='w-full focus:outline-none text-xl md:text-4xl        md:font-light font-normal text-black placeholder-red-800 font-josefin-sans' />
                            <CgSearch class='text-red-800' size={35} />
                        </div>
                    </div>
                    <div>
                        <img src={support} alt="support" />
                    </div>
                </div>

                <div className='flex flex-wrap justify-center gap-12 pt-20'>
                    <div onClick={handleDescription} className='hover:cursor-pointer w-[475px] font-josefin-sans'>
                        <img src={singleBlogImg} alt="blogListCard" className='w-[438px] h-[234px] rounded-2xl m-auto' />
                        <div className='text-left p-[14px]'>
                            <p className='text-2xl font-semibold pt-5'>How to Generate SFW (Safe-For-Work) .</p>
                            <p className='text-[24px] font-light leading-tight pt-6'>If you're wondering how to generate safe-for-work pictures, this post might help.</p>
                        </div>
                    </div>
                    <div onClick={handleDescription} className='hover:cursor-pointer w-[475px] font-josefin-sans'>
                        <img src={singleBlogImg} alt="blogListCard" className='w-[438px] h-[234px] rounded-2xl m-auto' />
                        <div className='text-left p-[14px]'>
                            <p className='text-2xl font-semibold pt-5'>How to Generate SFW (Safe-For-Work) .</p>
                            <p className='text-[24px] font-light leading-tight pt-6'>If you're wondering how to generate safe-for-work pictures, this post might help.</p>
                        </div>
                    </div><div onClick={handleDescription} className='hover:cursor-pointer w-[475px] font-josefin-sans'>
                        <img src={safeforwork} alt="blogListCard" className='w-[438px] h-[234px] rounded-2xl m-auto' />
                        <div className='text-left p-[14px]'>
                            <p className='text-2xl font-semibold pt-5'>How to Generate SFW (Safe-For-Work) .</p>
                            <p className='text-[24px] font-light leading-tight pt-6'>If you're wondering how to generate safe-for-work pictures, this post might help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupportCategory