import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Togal from '../../components/Togal'
import { IoClose } from 'react-icons/io5'
import { DeleteImg, eye, rename } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const Faqs = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/addFaqs')
    }

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleClose = () => {
        setIsPopupOpen(false);
    };


    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='FAQs List' placename='Search Blog' btnname='Add FAQ' btnfunction={handleBlog} />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-fixed">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">Questions</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">Answers</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Pages</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Status</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white text-xl font-normal text-black border-b border-stone-300">
                                <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                    What is the waiting period for accessing the platform after making a purchase?
                                </td>
                                <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                    If you choose to pay with a credit card (CCBill), you will gain ........to pay with a credit card
                                </td>
                                <td className="px-2 2xl:px-6 py-4 whitespace-nowrap">Plan</td>
                                <td className="px-2 2xl:px-6 py-4"><Togal /></td>
                                <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                    <img src={eye} alt="eye" className='hover:cursor-pointer' onClick={togglePopup} />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="delete" className='hover:cursor-pointer' />
                                </td>
                            </tr>
                            <tr className="bg-white text-xl font-normal text-black border-b border-stone-300">
                                <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                    What is the waiting period for accessing the platform after making a purchase?
                                </td>
                                <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                    If you choose to pay with a credit card (CCBill), you will gain ........to pay with a credit card
                                </td>
                                <td className="px-2 2xl:px-6 py-4 whitespace-nowrap">Plan</td>
                                <td className="px-2 2xl:px-6 py-4"><Togal /></td>
                                <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                    <img src={eye} alt="eye" className='hover:cursor-pointer' onClick={togglePopup} />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="delete" className='hover:cursor-pointer' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-10">
                    <div className="bg-white pt-5 pb-10 px-10 rounded-lg text-left shadow-lg w-[1100px]">
                        <div className='relative left-[100%]'>
                            <IoClose size={29} onClick={handleClose} className='hover:cursor-pointer' />
                        </div>
                        <p className="mb-4 font-semibold text-2xl">Questions</p>
                        <p className="mb-4 font-semibold text-lg">What is the waiting period for accessing the platform after making a purchase?</p>
                        <p className="mb-4 font-semibold text-2xl">Answers</p>
                        <p className="mb-4 font-semibold text-lg">If you choose to pay with a credit card (CCBill), you will gain access to the plan almost immediately after making the purchase. However, please note that there may be a delay of up to 3 minutes before access is fully granted.</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default Faqs