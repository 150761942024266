import React from 'react'
import { useNavigate } from 'react-router-dom/dist'
import Togal from '../../components/Togal'
import { DeleteImg, eye, rename, tableUser1, tableUser2 } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const OurStaff = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/addStaff')
    }

    const handleProflie = () => {
        navigate('/admin/userProfile')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Staff' placename='Search Staff' btnname='Add Staff' btnfunction={handleBlog} />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left border-stone-300">
                        <thead className="text-xl text-black  bg-stone-300">
                            <tr>
                                <th scope="col" className=" px-6 py-3">
                                    Staff
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Role
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className=" px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b text-black font-normal text-xl  border-stone-300">
                                <tr onClick={handleProflie}>
                                    <td
                                        className="px-6 flex items-center py-4  whitespace-nowrap "
                                    >
                                        <img src={tableUser1} alt="user2" className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                        <td className="whitespace-nowrap ">
                                            <td className="px-4 text-lg  whitespace-nowrap ">Gagandeep singh</td>
                                        </td>
                                    </td>
                                </tr>
                                <td className="px-6 py-4 whitespace-nowrap ">gagan.pyt@gmail.com</td>
                                <td className="px-6 py-4 whitespace-nowrap ">SEO Manager</td>
                                <td className="px-6 py-4"><Togal /></td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                            <tr className="bg-white border-b text-black font-normal text-xl  border-stone-300">
                                <tr onClick={handleProflie}>
                                    <td
                                        className="px-6 flex items-center py-4  whitespace-nowrap "
                                    >
                                        <img src={tableUser2} alt="user2" className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                        <td className="whitespace-nowrap ">
                                            <td className="px-4 text-lg  whitespace-nowrap ">Gagandeep singh</td>
                                        </td>
                                    </td>
                                </tr>
                                <td className="px-6 py-4 whitespace-nowrap ">gagan.pyt@gmail.com</td>
                                <td className="px-6 py-4 whitespace-nowrap ">SEO Manager</td>
                                <td className="px-6 py-4"><Togal /></td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OurStaff