import React from 'react'

//const InputBox = ({ placholderName, size, title, textType }) => {
//    return (
//        <>
//            <div className='pb-6 font-josefin-sans'>
//                <label className='text-zinc-500 font-semibold text-[22px]'>{title}</label>
//                <div className={`bg-white px-2 py-3 border mt-[10px] border-zinc-300  rounded-lg flex justify-between items-center  ${`w-[${size}]`} `}>
//                    <input type={textType} className='ps-5 text-xl focus:outline-none w-full placeholder:text-zinc-300' placeholder={placholderName} />
//                </div>
//            </div>
//        </>
//    )
//}


const InputBox = ({ placeholderName, size, title, textType, value, onChange }) => {
    return (
        <div className='pb-6 font-josefin-sans'>
            <label className='text-zinc-500 font-semibold text-[22px]'>{title}</label>
            <div className={`bg-white px-2 py-3 border mt-[10px] border-zinc-300 rounded-lg flex justify-between items-center ${`w-[${size}]`}`}>
                <input
                    type={textType}
                    className='ps-5 text-xl focus:outline-none w-full placeholder:text-zinc-300'
                    placeholder={placeholderName}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}


export default InputBox
