import React from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import { aiModal1, aiModal2, info } from '../../assets'

const SearchExtension = () => {
    return (
        <>
            <div className='flex justify-center gap-5 pt-10 md:pt-20'>
                <p className='font-semibold text-3xl xl:text-4xl pb-5'>Extensions</p>
                <img src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='bg-white rounded-xl py-6 px-6 '>
                <div className='px-5 py-2 flex border border-black w-full items-center rounded-full'>
                    <IoSearchOutline color='black' size={23} /> <input type="search" placeholder='Search Extension' className='focus:outline-none w-full text-black text-xl ps-3' />
                </div>
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Oral
                </div>
                <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                    <img src={aiModal1} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal2} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal1} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal2} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                </div>
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Ass
                </div>
                <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                    <img src={aiModal1} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal2} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal1} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                    <img src={aiModal2} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                </div>
            </div>
        </>
    )
}

export default SearchExtension