import React from 'react'
import { img1, img3, img4, img7 } from '../../../assets'
import { GoPlusCircle } from 'react-icons/go'

const ByEromanticai = () => {
    const imgdata = [
        { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, },
    ]

    return (
        <>

            <div className='flex justify-center mb-10 xl:mb-5'>
                <div className='rounded-full xl:w-[85%] bg-[#24FF003B] border-2 border-green-500 text-green-500 px-5 py-3'>
                    <p className='text-center text-lg lg:text-xl font-medium w-full'>Share your characters with the public and earn 0.2 credits for each picture generated by other users.</p>
                </div>
            </div>
            <div className='flex flex-wrap justify-center gap-4'>
                {
                    imgdata.map((data, index) => {
                        return (
                            <div className='flex flex-col'>
                                <div key={index} className='relative w-72 xl:w-[383px] h-80 xl:h-[524px] xl:mt-7 rounded-tr-xl rounded-tl-xl'>
                                    <img src={data.images} alt="img1" className='w-72 xl:w-[383px] h-80 xl:h-[524px] rounded-tr-xl rounded-tl-xl' />
                                    <button className='bg-red-800 flex items-center gap-2 text-white font-medium rounded-full absolute px-5 py-2 bottom-10 ml-[80px] xl:ml-[130px]'>
                                        <GoPlusCircle size={20} /> Unsave
                                    </button>
                                </div>
                                <div className='bg-red-800 text-xl lg:text-3xl text-center text-white font-medium rounded-br-xl rounded-bl-xl py-4'>
                                    Keyle
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default ByEromanticai
