import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";

function PrivacyPolicy() {
  return (
    <div>
      <div className="bg-black ">
        <div className="container mx-auto">
          <div className="p-10">
            <p className="text-white text-center py-10 md:text-[50px] text-[20px] mt-[50px]">
              Privacy Policy
            </p>
            <div>
            <h1 className="text-white text-[25px] mb-[-35px]">Introduction</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                This privacy policy governs the collection, processing, and protection of personal data by Eros Media LLC, headquartered , 1000 Brickell Ave, Ste 715 Miami, FL 33131.              </p>


              <h1 className="text-white text-[25px] mb-[-35px]">Information Collection and Use</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                When you join our platform using third-party social apps such as Google, Twitter, Reddit, or Discord, we may collect certain personal information from your authorized account. This information may include your email address (if available) and username. We use this information to create and maintain your account and provide you with access to the platform's features. Should you make a purchase via CCBill or Epoch, we store the necessary information we need for invoicing purposes, except for sensitive payment data. If you would like to know which data we collect specifically, please contact us via email. By utilizing the feature allowing users to upload images for the purpose of AI image recreation, you affirm that you possess the requisite legal rights and permissions pertaining to the uploaded images. Furthermore, you retain the authority to remove any uploaded images at your discretion. We may also use your email address to notify you of platform updates or offers. You are free to unsubscribe from this newsletter by clicking the 'unsubscribe' button in the received emails. We also store your IP address for security reasons.              </p>


              <h1 className="text-white text-[25px] mb-[-35px]">Google Analytics</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                We use Google Analytics to analyze the usage of our platform. Google Analytics collects various types of information, including your IP address, browser type, device information, and usage data. This information helps us understand user behavior, improve our platform's functionality, and optimize user experience. Please note that Google Analytics operates independently and has its own privacy practices, which are governed by Google's Privacy Policy.</p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Information Sharing and Disclosure</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                We do not sell, trade, or disclose your personal information to third parties for their marketing purposes. However, we may share your personal information in the following circumstances: With service providers who assist us in operating our platform and providing our services. These service providers are bound by confidentiality obligations and are only authorized to use your personal information as necessary to provide the requested services. In response to a legal request or to comply with applicable laws, regulations, or legal processes. To protect our rights, property, and safety, as well as the rights, property, and safety of our users or others.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Data Retention</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We will securely delete or anonymize your personal information when it is no longer needed for the purposes for which it was collected. You have the option to delete your account at www.eromantic.ai/remove-account. Upon deletion, all your personal data will be erased, with the exception of any documents retained for bookkeeping purposes.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Children's Privacy</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                Our platform is intended for individuals who are at least 18 years old or the age of majority in their jurisdiction. We do not knowingly collect personal information from children under the age of 18. If we become aware that we have inadvertently collected personal information from a child under the age of 18, we will take steps to delete that information.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Contact us</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at support@eromantic.ai

              </p>

            </div>
            <div className=" max-w-full mt-2  border-t border-white">
              <div className="flex justify-between  gap-4 text-white pt-5 pb-5">
                <Link to="/blogs" className="text-[14px] lg:text-lg">
                  Blogs
                </Link>
                <Link to="/terms-of-service" className="text-[14px] lg:text-lg">
                  Terms of Service
                </Link>
                <Link to="/privacy-policy" className="text-[14px] lg:text-lg">
                  Privacy Policy
                </Link>
                <Link to="/cookie-policy" className="text-[14px] lg:text-lg">
                  Cookie Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
