import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import InputBox from '../../components/InputBox'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const AddStaff = () => {


    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/ourStaff')
    }

    const [permissions, setPermissions] = useState([
        { title: 'Dashboard', read: false, write: false, create: false },
        { title: 'Users', read: false, write: false, create: false },
        { title: 'Our Staff', read: false, write: false, create: false },
        { title: 'Invoice', read: false, write: false, create: false },
        { title: 'Blogs', read: false, write: false, create: false },
        { title: 'Help Desk', read: false, write: false, create: false },
        { title: 'FAQs', read: false, write: false, create: false },
        { title: 'Settings', read: false, write: false, create: false },
    ]);

    const handleCheckboxChange = (index, permission) => {
        const updatedPermissions = [...permissions];
        updatedPermissions[index][permission] = !updatedPermissions[index][permission];
        setPermissions(updatedPermissions);
    };

    const handleSelectAll = (selectAll) => {
        const updatedPermissions = permissions.map((item) => ({
            ...item,
            read: selectAll,
            write: selectAll,
            create: selectAll,
        }));
        setPermissions(updatedPermissions);
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Add Staff' btnname='Back to staff list' btnfunction={handleBlog} />
                <InputBox title='Staff Name' placholderName='Full Name' />
                <InputBox title='Staff Email' placholderName='Email' />
                <InputBox title='Mobile Number' placholderName='Mobile Number' />
                <InputBox title='Role Name' placholderName='Role' />
                <InputBox title='Password' placholderName='Password' />
                <InputBox placholderName='Confirm Password' />

                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Access Permissions
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Read
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Write
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Create
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center gap-5 justify-center">
                                        <input
                                            id="checkbox-select-all"
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            onChange={(e) => handleSelectAll(e.target.checked)}
                                        />
                                        <label htmlFor="checkbox-select-all" className="sr-only">
                                            checkbox
                                        </label>
                                        <td>
                                            Select All
                                        </td>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {permissions.map((item, index) => (
                                <tr key={index} className="bg-white border-b text-xl font-normal text-zinc-600 border-stone-300">
                                    <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="checkbox"
                                            checked={item.read}
                                            onChange={() => handleCheckboxChange(index, 'read')}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="checkbox"
                                            checked={item.write}
                                            onChange={() => handleCheckboxChange(index, 'write')}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="checkbox"
                                            checked={item.create}
                                            onChange={() => handleCheckboxChange(index, 'create')}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                    </td>
                                    <td className="px-6 py-4"></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Add User</button>
            </div>
        </>
    )
}

export default AddStaff