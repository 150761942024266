import React from 'react'
import InputBox from '../../../components/InputBox'

const Security = () => {
    return (
        <>
            <div className='font-josefin-sans'>
                <p className='text-2xl font-semibold mt-10'>Change Password</p>
                <div className='w-full flex justify-between mt-7'>
                    <div className='w-[48%]'>
                        <InputBox placholderName='Current Password' title='Current Password' textType='password' />
                        <InputBox placholderName='New Password' title='New Password' textType='password' />
                        <p className='text-xl font-semibold text-stone-500 pb-2 xl:pb-3'>Password Requirements:</p>
                        <ul>
                            <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >Minimum 8 characters long - the more, the better</li>
                            <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >At least one lowercase character</li>
                            <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >At least one number, symbol, or whitespace character</li>
                        </ul>

                    </div>
                    <div className='w-[48%] pt-28'>
                        <InputBox placholderName='Confirm New Password' title='Confirm New Password' textType='password' />

                    </div>
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save Changes</button>
                </div>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4'>Reset</button>
                </div>
            </div>
        </>
    )
}

export default Security
