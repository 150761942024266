import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BASE_URL = "https://api.eromantic.ai/api/"; // Adjust to your Django API URL

const Sitemap = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name.toLowerCase();
            if (
                fileName.endsWith('.xml') ||
                fileName.endsWith('.txt') ||
                fileName.endsWith('.json')
            ) {
                setFile(selectedFile);
                setError(null); // Reset errors when a new file is selected
                setSuccess(null); // Reset success message
            } else {
                setError("Invalid file type. Please upload an XML, TXT, or JSON file.");
            }
        }
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            setError("Please select a file to upload.");
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await fetch(`${BASE_URL}upload`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess("Sitemap file uploaded and replaced successfully!");
                setFile(null); // Reset file after upload
            } else {
                setError(data.message || "Failed to upload the file.");
            }
        } catch (err) {
            console.log("Error:", err);
            setError("An error occurred while uploading the file.");
        } finally {
            setUploading(false);
        }
    };

    // Handle file delete (reset the file input)
    const handleDelete = () => {
        setFile(null);
        setError(null);
        setSuccess(null);
    };

    return (
        <div className="bg-white px-7 pb-20 font-josefin-sans">
            <div className="flex items-center gap-3 py-9">
                <p className="text-[28px] font-semibold text-gray-900">Upload Sitemap</p>
            </div>

            <div className="space-y-5">
                {/* File input */}
                <div>
                    <label htmlFor="sitemap-file" className="block text-sm font-medium text-gray-700">
                        Choose a sitemap file (XML, TXT, or JSON)
                    </label>
                    <input
                        id="sitemap-file"
                        type="file"
                        accept=".xml,.txt,.json" // Accept XML, TXT, and JSON files
                        onChange={handleFileChange}
                        className="mt-2 block w-full text-sm text-gray-700 border border-gray-300 rounded-lg shadow-sm"
                    />
                </div>

                {/* Display the selected file name and delete button */}
                {file && (
                    <div className="flex items-center gap-2 mt-2">
                        <span className="text-sm text-gray-700">{file.name}</span>
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="text-sm text-red-500 hover:text-red-700"
                        >
                            Delete
                        </button>
                    </div>
                )}

                {/* Upload Button */}
                <div>
                    <button
                        onClick={handleUpload}
                        disabled={uploading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {uploading ? "Uploading..." : "Upload Sitemap"}
                    </button>
                </div>

                {/* Error and success messages */}
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
            </div>
        </div>
    );
};

export default Sitemap;
