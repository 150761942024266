import React from 'react'
import { Instagram, Logo, Twitter, VisaImg } from '../../assets';

const Footer = () => {
    return (
        <>
            <div className='px-2 md:px-16 py-5'>
                <img src={Logo} alt="logo" className='m-auto pb-5 w-60 md:w-auto' />
                <div className='flex items-center gap-3 md:gap-5 justify-center pb-5 text-base md:text-2xl font-medium'>
                    <p className='text-xl md:text-3xl font-semibold'>Popular :</p>
                    <p>Features</p>
                    <p>Features</p>
                    <p>Features</p>
                </div>
                <p className='text-center m-auto md:w-[70%] font-medium pb-5'>The content on this website is generated by AI for entertainment purposes only and does not portray
                    real individuals or events. Visitors are advised to use their own discretion, and neither the website nor
                    its operators are liable for any consequences resulting from the use of this content.</p>
                <div className='flex justify-center items-center gap-10 pb-5 md:pb-10 '>
                    <img src={Twitter} alt="twitter" className='w-9 md:w-11 lg:w-auto' />
                    <img src={Instagram} alt="insta" className='w-9 md:w-11 lg:w-auto' />
                </div>
                <div className='border border-t-black border-b-gray-500 flex flex-wrap justify-center md:justify-between md:items-center py-2'>
                    <div className='flex justify-center items-center gap-4 xl:gap-5 pb-1 md:pb-0 text-[13px] md:text-[14px] lg:text-base xl:text-xl font-medium'>
                        <p>Terms of service</p>
                        <p>Privacy policy</p>
                        <p>Cookie policy</p>
                    </div>
                    <div className='flex justify-center items-center gap-5 md:gap-2 xl:gap-5'>
                        <p className='md:text-[15px] lg:text-lg xl:text-2xl font-medium'>WE ACCEPT</p>
                        <img src={VisaImg} alt="visa" className='w-32 md:w-40 lg:w-56 xl:w-auto' />
                    </div>
                </div>
                <p className='text-[13px] md:text-base font-medium text-center pt-2 md:pt-5'>© 2024 EROMANTIC.AI. All Rights Reserved - Sitemap</p>
            </div>
        </>
    )
}

export default Footer