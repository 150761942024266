

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DeleteImg, Dote, rename, singleBlogImg } from '../../../assets';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import DeletePopup from '../../components/DeletePopup';
import { updateMetaTags } from '../../components/SetMetadata'; // Adjust the import path as needed
import axios from 'axios';
import { Helmet } from 'react-helmet';


const BASE_URL = "https://api.eromantic.ai/api/";

const SingleBlog = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const location = useLocation();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [blog, setBlog] = useState(location.state?.slug || null);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };


    useEffect(() => {
        if (!blog) {
            const fetchBlog = async () => {
                try {
                 const authToken = localStorage.getItem('authToken');
                    const response = await axios.get(`${BASE_URL}details_blog/${slug}/`);
                    setBlog(response.data);

                    // Define meta tags
                    const metaTags = {
                        'title': response.data.meta_title,
                        'description': response.data.meta_description,
                        'keywords': response.data.meta_keywords
                    };

                    // Update meta tags
                    updateMetaTags(metaTags);

                } catch (error) {
                    console.error('Error fetching blog:', error);
                }
            };

            fetchBlog();
        }
    }, [blog, slug]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const handleBlog = () => {
        navigate('/blogs');
    };

    return (
        <>
        <Helmet>
                {blog && (
                    <>
                        <title>{blog.meta_title}</title>
                        <meta name="description" content={blog.meta_description} />
                        <meta name="keywords" content={blog.meta_keywords} />
                    </>
                )}
            </Helmet>
            <div className='bg-black text-white px-20 xl:px-52 py-10 font-josefin-sans'>

                <div className='flex justify-between items-center py-5 bg-black'>
                    <div className='flex gap-5'>
                        <div className='flex items-center gap-3'>
                            <a onClick={handleBlog} className='text-[28px] text-white font-light cursor-pointer'>
                                <i className="fa-solid fa-chevron-left fa-xs me-1"></i>
                                Blog List
                            </a>
                        </div>
                    </div>
                </div>
                <h2 className='pb-5'>{blog.title}</h2>
                <img src={blog.image} alt='LandingHeroImg' className='w-full h-[329px] lg:h-[629px] object-contain rounded-2xl' />
                <div
                    className='text-white py-3 pLineHeight'
                    dangerouslySetInnerHTML={{ __html: blog.description }}
                />
            </div>

        </>
    );
};

export default SingleBlog;

