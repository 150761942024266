import React, { useState } from "react";
import Footer from "../components/Footer";

const PricePlan = () => {
    const [active, setActive] = useState("monthly");

    const renderContent = () => {
        switch (active) {
            case "monthly":
                return (
                    <div>
                        <div className="flex flex-wrap justify-center lg:gap-3">
                            <div className="bg-[#7a3b00] lg:h-[500px] xl:h-[666px] border border-[#CD7F32] text-[#CD7F32] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-norma">Bronze</h2>
                                <p>$25</p>
                            </div>
                            <div className="bg-[#606060] lg:h-[500px] xl:h-[666px] border border-[#C0C0C0] text-white p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Silver</h2>
                            </div>
                            <div className="bg-[#635712] lg:h-[500px] xl:h-[666px] text-[#FFD700] border border-[#FFD700] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Gold</h2>
                            </div>
                        </div>
                        <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-[20px] mt-5">* The 'replicate face or pose from an uploaded image' feature is currently in the experimental stage and requires compliance with our terms of use. Access to this feature is limited to those who make purchases using Epoch or Cryptocurrency, as CCBill prohibits image uploads to the platform. Additionally, if you plan to generate an image of a real person, you are required to provide us with the person's ID document & consent for verification purposes. We do this to comply with laws and regulations.</p>
                    </div>

                );
            case "sixMonths":
                return (
                    <div>
                        <div className="flex flex-wrap justify-center lg:gap-3">
                            <div className="bg-[#7a3b00] lg:h-[500px] xl:h-[666px] border border-[#CD7F32] text-[#CD7F32] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-norma">Bronze - 6 Months</h2>
                                <p>$130</p>
                            </div>
                            <div className="bg-[#606060] lg:h-[500px] xl:h-[666px] border border-[#C0C0C0] text-white p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Silver - 6 Months</h2>
                            </div>
                            <div className="bg-[#635712] lg:h-[500px] xl:h-[666px] text-[#FFD700] border border-[#FFD700] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Gold - 6 Months</h2>
                            </div>
                        </div>
                        <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-[20px] mt-5">* The 'replicate face or pose from an uploaded image' feature is currently in the experimental stage and requires compliance with our terms of use. Access to this feature is limited to those who make purchases using Epoch or Cryptocurrency, as CCBill prohibits image uploads to the platform. Additionally, if you plan to generate an image of a real person, you are required to provide us with the person's ID document & consent for verification purposes. We do this to comply with laws and regulations.</p>
                    </div>
                );
            case "twelveMonths":
                return (
                    <div>
                        <div className="flex flex-wrap justify-center lg:gap-3">
                            <div className="bg-[#7a3b00] lg:h-[500px] xl:h-[666px] border border-[#CD7F32] text-[#CD7F32] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-norma">Bronze - 12 Months</h2>
                                <p>$250</p>
                            </div>
                            <div className="bg-[#606060] lg:h-[500px] xl:h-[666px] border border-[#C0C0C0] text-white p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Silver - 12 Months</h2>
                            </div>
                            <div className="bg-[#635712] lg:h-[500px] xl:h-[666px] text-[#FFD700] border border-[#FFD700] p-10 rounded-lg m-2 w-60 h-[300px] lg:w-2/6 xl:w-1/4 text-center">
                                <h2 className="text-2xl font-normal">Gold - 12 Months</h2>
                            </div>
                        </div>
                        <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-[20px] mt-5">* The 'replicate face or pose from an uploaded image' feature is currently in the experimental stage and requires compliance with our terms of use. Access to this feature is limited to those who make purchases using Epoch or Cryptocurrency, as CCBill prohibits image uploads to the platform. Additionally, if you plan to generate an image of a real person, you are required to provide us with the person's ID document & consent for verification purposes. We do this to comply with laws and regulations.</p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="bg-black text-white text-center py-10 font-josefin-sans lg:px-10 xl:px-0 2xl:px-20">
                <p className="text-[35px] lg:text-[40px]">Choose your Plan</p>
                <p className="text-[20px] lg:text-[24px] pb-2">Need help? Reach out</p>
                <div className="flex items-center justify-center">
                    <div className="relative bg-gray-800 border border-gray-600 rounded-full opacity-75 md:w-6/12 lg:w-5/12 xl:w-3/12">
                        <div
                            className={`absolute top-0 bottom-0 left-0 w-1/3 border-2 border-[#FF0013] bg-red-900 rounded-full transform transition-transform duration-300 ease-in-out ${active === "sixMonths"
                                ? "translate-x-full"
                                : active === "twelveMonths"
                                    ? "translate-x-[200%]"
                                    : ""
                                }`}
                        ></div>
                        <div className="relative z-10 p-1 flex justify-between">
                            <button
                                onClick={() => setActive("monthly")}
                                className={`flex-1 px-4 py-2 text-sm font-medium rounded-full ${active === "monthly" ? "text-white" : "text-gray-50"
                                    }`}
                            >
                                MONTHLY
                            </button>
                            <button
                                onClick={() => setActive("sixMonths")}
                                className={`flex-1 px-4 py-2 text-sm font-medium rounded-full ${active === "sixMonths" ? "text-white" : "text-gray-50"
                                    }`}
                            >
                                6 MONTHS
                            </button>
                            <button
                                onClick={() => setActive("twelveMonths")}
                                className={`flex-1 px-4 py-2 text-sm font-medium rounded-full ${active === "twelveMonths" ? "text-white" : "text-gray-50"
                                    }`}
                            >
                                12 MONTHS
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <button
                        type="button"
                        className="py-2 px-7 2xl:px-10 me-2 mb-2 text-sm font-medium text-white bg-red-900 rounded-lg border border-[#FF0013] hover:border-[#ff0000] focus:z-10"
                    >
                        Card (EPOCH)
                    </button>
                    <button
                        type="button"
                        className="py-2 px-7 2xl:px-10 me-2 mb-2 text-sm font-medium text-white bg-red-900 rounded-lg border border-[#FF0013] hover:border-[#ff0000] focus:z-10"
                    >
                        Card (EPOCH)
                    </button>
                    <button
                        type="button"
                        className="py-2 px-7 2xl:px-10 me-2 mb-2 text-sm font-medium text-white bg-red-900 rounded-lg border border-[#FF0013] hover:border-[#ff0000] focus:z-10"
                    >
                        Card (EPOCH)
                    </button>
                </div>

                <div className="mt-10 px-5 lg:px-0">
                    {renderContent()}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PricePlan;
