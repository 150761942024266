import React from "react";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useState } from "react";

const NotifyModal = ({ isOpen, setIsOpen }) => {
  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }
  return (
    <>

      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="relative z-[1000] focus:outline-none"
          onClose={close}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/[58%]">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-[897px] flex flex-col justify-center items-center md:h-[569px] bg-white py-10 px-6 ">
                  
                    <div className=" flex justify-center mb-[40px] md:mb-[85px]">
                      <img src="/assets/img/rightmark.svg" alt="" className="md:max-w-[120px] lg:max-w-[187px] w-full max-w-[100px]"/>
                    </div>
                    <p className="text-center text-[28px] sm:text-[40px] md:text-[48px] lg:text-[58px]">Thank You For Subscribing!</p>
                
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NotifyModal;
