import React from 'react'
import { accountProfileImage } from '../../../assets'

const AccountProfile = () => {
    return (
        <>
            <div className='flex flex-col h-[1200px] font-josefin-sans bg-black '>
                <div className='bg-red-800 h-[45vh]'>
                    <p className=' text-3xl font-bold text-white text-center mt-10 lg:mt-20'>Profile Info</p>
                    <div className='flex justify-center'>
                        <div className='bg-white rounded-2xl lg:w-[800px] xl:w-[1200px] h-[345px] lg:h-[398px] xl:h-[438px] lg:mt-48 mt-32 z-10 relative'>
                            <div className='px-3 md:px-10 py-5 xl:py-12'>
                                <div className='flex justify-between w-full'>
                                    <p className=' lg:text-xl font-bold text-left w-[20%] xl:w-[42%]'>Created Joined : 6 May 2024</p>
                                    <div className='w-[40%] xl:w-[30%]'>
                                        <img src={accountProfileImage} alt="accountProfileImage" className='m-auto absolute     bottom-64  w-32 h-w-32 md:w-44 md:h-44 lg:w-[285px] lg:h-[285px] xl:w-72 xl:h-72 2xl:w-[300px] 2xl:h-[300px] rounded-full' />
                                    </div>
                                    <p className=' lg:text-xl text-right font-bold w-[20%] xl:w-[38%]'>Edit Profile</p>
                                </div>
                                <p className='text-3xl font-bold flex justify-center items-center mt-20 lg:mt-36'>Gagandeep Singh</p>
                                <div className='flex justify-center items-center gap-5 md:gap-20 mt-3 xl:mt-10'>
                                    <p className=' lg:text-xl font-bold '>0 Your Generations</p>
                                    <p className=' lg:text-xl font-bold '>0 Likes</p>
                                    <p className=' lg:text-xl font-bold '>0 Followers</p>
                                </div>
                            </div>
                            <div className='mt-4 xl:mt-0 flex border border-red-800 justify-between rounded-bl-xl rounded-br-xl'>
                                <button className='bg-red-800 w-full md:text-2xl h-16 rounded-bl-xl text-center text-white'>
                                    Public Generation
                                </button>
                                <button className='bg-white w-full md:text-2xl rounded-br-xl text-center text-red-800'>
                                    Most Liked
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='bg-black h-[55vh]'>

                </div> */}
            </div>
        </>
    )
}

export default AccountProfile