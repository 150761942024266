import React from 'react'

const SeoContent = () => {
    return (
        <>
            <p className='text-2xl font-semibold mt-10 mb-6'>Seo Content</p>
            <label className='text-zinc-500 font-semibold text-[22px]'>Head</label>
            <textarea name="head" id="" className='border border-zinc-300 text-lg font-medium focus:outline-none p-3 w-full h-28 mt-3 rounded-lg'></textarea>
            <label className='text-zinc-500 font-semibold text-[22px]'>Body</label>
            <textarea name="body" id="" className='border border-zinc-300 text-lg font-medium focus:outline-none p-3 w-full h-28 mt-3 rounded-lg'></textarea>
            <label className='text-zinc-500 font-semibold text-[22px]'>Footer</label>
            <textarea name="footer" id="" className='border border-zinc-300 text-lg font-medium focus:outline-none p-3 w-full h-28 mt-3 rounded-lg'></textarea>
            <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save</button>

        </>
    )
}

export default SeoContent