import React from 'react';
import Header from '../components/Header';

const LayoutDesbord = ({ children }) => (
    <div>
        <header>
            <Header />
        </header>
        <div>
            <div className="main-container float-start w-full">
                {children}
            </div>
        </div>
    </div>
);

export default LayoutDesbord;
