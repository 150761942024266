import React from 'react'
import { useNavigate } from 'react-router-dom'
import InputBox from '../../../components/InputBox'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const AddUser = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/allUser')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Add User' btnname='Back to user list' btnfunction={handleBlog} />
                <div>
                    <InputBox title='User Name' placholderName='Full Name' />
                    <InputBox title='User Email' placholderName='Email' />
                    <InputBox title='Mobile Number' placholderName='Mobile Number' />
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-lg text-white p-4'>Add User</button>
            </div>
        </>
    )
}

export default AddUser