import React, { useState, useEffect } from 'react';
import InputBox from '../../../components/InputBox';
import Dropdown_ct from '../../../components/category_dropdown';
import { useNavigate } from 'react-router-dom';
import { Dote, imgSelector } from '../../../../assets';
import PagenameSearchBox from '../../../components/PagenameSearchBox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

const CreateBlog = () => {
    // State management
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [tags, setTags] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        setEditorLoaded(true);
        fetchCategories();
    }, []);

    function convertOntoSlug(text) {
        return text
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }

    const fetchCategories = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.get('https://api.eromantic.ai/api/list_category/', {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });
            const formattedCategories = response.data.map(category => ({
                value: category.id,
                label: category.title
            }));
            setCategories(formattedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleBlog = () => {
        navigate('/admin/blogList');
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file.type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }
        setSelectedImage(file);
    };

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
            this.url = 'https://api.eromantic.ai/api/upload_image/';
            this.authToken = localStorage.getItem('authToken');
        }

        upload() {
            return this.loader.file
                .then(file => new Promise((resolve, reject) => {
                    const data = new FormData();
                    data.append('image', file);

                    fetch(this.url, {
                        method: 'POST',
                        body: data,
                        headers: {
                            'Authorization': `Token ${this.authToken}`,
                        },
                    })
                    .then(response => {
                        if (!response.ok) {
                            return response.json().then(error => {
                                reject(error);
                            });
                        }
                        return response.json();
                    })
                    .then(result => {
                        if (result && result.url) {
                            resolve({
                                default: result.url
                            });
                        } else {
                            reject('Failed to upload image. No URL returned.');
                        }
                    })
                    .catch(error => {
                        console.error('Upload error:', error);
                        reject('An error occurred during the upload.');
                    });
                }));
        }

        abort() {
            // Handle abort actions if necessary
        }
    }

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    const handleSubmit = async () => {
        if (isSubmitting) return;

        if (!title || !slug || !description || !metaTitle || !metaDescription || !metaKeywords || !selectedCategory || !selectedImage) {
            alert('Please fill in all fields.');
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('slug', convertOntoSlug(slug));
        formData.append('description', `${description}`);
        formData.append('meta_title', metaTitle);
        formData.append('meta_description', metaDescription);
        formData.append('meta_keywords', metaKeywords);
        formData.append('category', selectedCategory); // This should now be set correctly
        formData.append('tags', '');
        formData.append('image', selectedImage);

        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.post('https://api.eromantic.ai/api/create_blog/', formData, {
            headers: {
                'Authorization': `Token ${authToken}`,
                },
            });
            console.log(response)
            if (response.status != 201) {
                const errorData = await response.json();
                console.error('Server response:', errorData);
                throw new Error('Network response was not ok');
            }

            handleBlog();
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox
                    title='Blog List'
                    titlesecond='Create Blog'
                    imgDote={Dote}
                    btnname='Back to blog list'
                    btnfunction={handleBlog}
                />
                <div className='w-full flex gap-6'>
                    <div className='w-[74%]'>
                        <InputBox
                            title='Blog Title'
                            placeholderName='Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <InputBox
                            title='Blog Slug'
                            placeholderName='Slug'
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                        />
                        <label className='text-zinc-500 font-semibold text-[22px]'>Blog Description</label>
                        <CKEditor
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                  writer.setStyle( 'height', '350px', editor.editing.view.document.getRoot());
                              });
                            }}
                            editor={ClassicEditor}
                            config={{
                                 extraPlugins: [MyCustomUploadAdapterPlugin],
                            }}
                            data={description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                            }}
                        />
                        <InputBox
                            title='Meta Title'
                            placeholderName='Title'
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                        />
                        <InputBox
                            title='Meta Description'
                            placeholderName='Description'
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                        />
                        <InputBox
                            title='Meta Keywords'
                            placeholderName='Keywords (Separated By Commas)'
                            value={metaKeywords}
                            onChange={(e) => setMetaKeywords(e.target.value)}
                        />
                    </div>
                    <div className='w-[25%]'>
                        <div className='pb-6 flex flex-col'>
                            <label className='text-zinc-500 font-semibold text-[22px]'>Categories</label>
                            <Dropdown_ct
                                onChange={setSelectedCategory} // Correctly set the category ID
                                options={categories}
                            />
                        </div>
                        <div className='pb-6 flex flex-col'>
                            <label className='text-zinc-500 pb-3 font-semibold text-[22px]'>Featured Image</label>
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-64 border border-zinc-300 rounded-lg cursor-pointer bg-white px-2 py-3"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        {selectedImage ? (
                                            <img
                                                src={URL.createObjectURL(selectedImage)}
                                                alt="Selected"
                                                className="w-full h-32 object-cover mb-4"
                                            />
                                        ) : (
                                            <img src={imgSelector} alt="imgSelector" className='w-11 lg:w-20' />
                                        )}
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            onChange={handleImageChange}
                                            accept="image/*"
                                        />
                                        <p className="mt-6 text-base text-stone-400">
                                            {selectedImage ? 'Change Featured Image' : 'Add Featured Image'}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className='rounded-lg w-full text-lg mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white p-4'
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Publishing...' : 'Publish Blog'}
                </button>
            </div>
        </>
    );
};

export default CreateBlog;
