import React from 'react'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { aiModal1, aiModal2, info } from '../../assets';

const SelectAIModel = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 4,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    }

    const data = [
        { img: aiModal1, }, { img: aiModal2, }, { img: aiModal1, }, { img: aiModal2, }, { img: aiModal1, }, { img: aiModal2, }, { img: aiModal1, }, { img: aiModal2, },
    ]

    return (
        <>
            <div className='pt-10 md:pt-20'>
                <div className='flex justify-center gap-5 pb-2'>
                    <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
                    <img src={info} alt="infoBlack" className='h-6' />
                </div>
                <Carousel partialVisible={true} responsive={responsive}>
                    {
                        data.map((item, index) => (
                            <img key={index} src={item.img} alt="aiModal1" className='w-[300px] h-[320px] xl:w-[270px] xl:h-[320px]' />
                        ))
                    }
                </Carousel>
            </div>
        </>
    )
}

export default SelectAIModel