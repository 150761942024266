//import React from 'react'
//import Recreateimage from '../../components/Recreateimage'
//import SelectAIModel from '../../components/SelectAIModel'
//import { info } from '../../../assets'
//import SearchExtension from '../../components/SearchExtension'
//import UseShortWordsInputBox from '../../components/UseShortWordsInputBox'
//import FooterMakeAI from '../../components/FooterMakeAI'
//
//const Simplified = () => {
//    const feature = [
//        { name: 'Characters', }, { name: 'Hair color', }, { name: 'Haircut', }, { name: 'Wearing', }, { name: 'Nationality', }, { name: 'Environment', }, { name: 'Style', }, { name: 'Effects', },
//    ]
//
//    const Girl = [
//        { name: '1 Girl', }, { name: '1 Girl, 1 Man', }, { name: '2 Girl, 2 Man', }, { name: '2 Girl', }, { name: '1 Girl, 2 Man', }, { name: '1 Man (Solo)', }, { name: '2girls, 2boys', },
//    ]
//
//    return (
//        <>
//            <p className='pt-5 text-xl xl:text-[25px] font-medium'>The basic version is for beginners. If you want more options and control, choose 'advanced' above. The simple version is easier to use, but the advanced one gives you more specific results.</p>
//            <Recreateimage />
//
//            <SelectAIModel />
//
//            <div className='flex justify-center gap-5 pt-10 md:pt-20'>
//                <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select Features</p>
//                <img src={info} alt="infoBlack" className='h-6' />
//            </div >
//            <div className='bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap'>
//                {
//                    feature.map((item, index) => (
//                        <p key={index} className='font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4'>{item.name}</p>
//                    ))
//                }
//            </div>
//
//            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>
//                {
//                    Girl.map((item, index) => (
//                        <div className='w-[190px] border-2 border-zinc-600 hover:text-red-600 hover:boreder hover:border-red-600 rounded-full mt-3 hover:cursor-pointer'>
//                            <p key={index} className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
//                        </div>
//                    ))
//                }
//                <div className='w-[150px] mt-3 hover:cursor-pointer'>
//                    <p className='font-semibold hover:text-red-600  text-2xl py-2 px-4 text-center'>Custom</p>
//                </div>
//            </div>
//
//            <SearchExtension />
//
//            <UseShortWordsInputBox name='Other (use short words and commas)' plachold='e.g. smiling, sunny weather' />
//            <UseShortWordsInputBox name='Face prompt (optional)' plachold='e.g. beutiful' />
//
//            <FooterMakeAI />
//        </>
//    )
//}
//
//export default Simplified














import React, {useState} from 'react';
import Recreateimage from '../../components/Recreateimage';
import SelectAIModel from '../../components/SelectAIModel';
import { info } from '../../../assets';
import SearchExtension from '../../components/SearchExtension';
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox';
import FooterMakeAI from '../../components/FooterMakeAI';
import { goldE } from '../../../assets';
import axios from 'axios';
import Cookies from 'js-cookie';


const Simplified = () => {
 const [activeComponent, setActiveComponent] = useState(null);
 const [activeComponent1, setActiveComponent1] = useState(null);
 const [activeComponent2, setActiveComponent2] = useState(null);
 const [activeComponent3, setActiveComponent3] = useState(null);
 const [activeComponent4, setActiveComponent4] = useState(null);
 const [activeComponent5, setActiveComponent5] = useState(null);
 const [activeComponent6, setActiveComponent6] = useState(null);
 const [loading, setLoading] = useState(false); // Loader state
 const [generatedImage, setGeneratedImage] = useState(null); // Generated image state
 const [error, setError] = useState(null); // Error state

    const handleClick = (index) => {
        setActiveComponent(index); // Set the active component based on the index
    };

    const handleClick1 = (index) => {
        setActiveComponent1(index); // Set the active component based on the index
    };
    const handleClick2 = (index) => {
        setActiveComponent2(index); // Set the active component based on the index
    };
    const handleClick3 = (index) => {
        setActiveComponent3(index); // Set the active component based on the index
    };
    const handleClick4 = (index) => {
        setActiveComponent4(index); // Set the active component based on the index
    };
    const handleClick5 = (index) => {
        setActiveComponent5(index); // Set the active component based on the index
    };
    const handleClick6 = (index) => {
        setActiveComponent6(index); // Set the active component based on the index
    };
    const feature = [
        { name: 'Characters', } ]

    const Girl = [
        { name: '1 Girl', }]

        const hairCol = [
        { name: 'blonde hair', }, { name: 'brown hair', }, { name: 'redhead hair', }]

        const hairstyle = [
        { name: 'bob cut', }, { name: 'pixie cut', }, { name: 'curly hair', }, { name: 'Shag hair', }]

        const nationality = [
        { name: 'american', }, { name: 'russian', }, { name: 'german', }, { name: 'asian', }]

        const Brest = [
        { name: '(small breasts)', }, { name: '(medium breasts)', }, { name: '(large breasts)', }, { name: '(huge breasts)', }]

        const Environment = [
        { name: 'in a street', }, { name: 'in a beach', }, { name: 'in a desert', }, { name: 'in a library', }, { name: 'in a Vineyard', }, { name: 'in a casino', }]
         const Effects = [
        { name: 'bokeh', }, { name: 'motion blur', }, { name: 'HDR', },{ name: 'Depth of field', }]


    const generateimage = async () => {
        const selectedData = {
           component1: Girl[activeComponent].name || 'No Selection',
            component2: hairCol[activeComponent1].name || 'No Selection',
            component3: hairstyle[activeComponent2].name || 'No Selection',
            component4: nationality[activeComponent3].name || 'No Selection',
            component5: Brest[activeComponent4].name || 'No Selection',
            component6: Environment[activeComponent5].name || 'No Selection',
            component7: Effects[activeComponent6].name || 'No Selection',
        };

      console.log('Generated image:', selectedData);
        setLoading(true); // Show loader
        setError(null);
        setGeneratedImage(null);

        try {

            const authToken = localStorage.getItem('authToken');
            console.log(authToken);

            const response = await axios.post('https://api.eromantic.ai/api/generate_image/', selectedData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`,
                }
            })
            // Convert the bytes to a base64 string
            // Convert the ArrayBuffer to a base64 string
//            const base64Image = btoa(
//                new Uint8Array(response.data)
//                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
//            );
//            const imageUrl = `data:image/jpeg;base64,${base64Image}`;
            const imageUrl = response.data.image;
            setGeneratedImage(imageUrl); // Store the image URL in state

            console.log('Generated image222222:', imageUrl);

            console.log('Generated image:', response.data);
        } catch (error) {
            console.error('Error generating image:', error);
            setError('Failed to generate image. Please try again.');
        }finally {
            setLoading(false); // Hide loader
        }
    };


    return (
        <>
    {/* Overlay for Loading */}
    {loading && (
        <div className="loading-overlay">
            <div className="loader">Loading...</div>
        </div>
    )}

    {/* Main content */}
    <div className={loading ? 'page-disabled' : ''}>
        <p className='pt-5 text-xl xl:text-[25px] font-medium'>The basic version is for beginners. If you want more options and control, choose 'advanced' above. The simple version is easier to use, but the advanced one gives you more specific results.</p>

        <SelectAIModel />

        <div className='flex justify-center gap-5 pt-10 md:pt-20'>
            <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select Features</p>
            <img src={info} alt="infoBlack" className='h-6' />
        </div >
        <div className='bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap'>
            {
                feature.map((item, index) => (
                    <p key={index} className='font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4'>{item.name}</p>
                ))
            }
        </div>

        <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>
            {Girl.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${activeComponent === index ? 'text-red-600 border-red-600' : 'border-zinc-600'} hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}
        </div>

       <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Hair color</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>

                {hairCol.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent1 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick1(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}

            </div>


            <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Hair Style</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>


                {hairstyle.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent2 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick2(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}

            </div>

            <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Nationality</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>


                 {nationality.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent3 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick3(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}



            </div>


            <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Breasts</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>



                {Brest.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent4 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick4(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}



            </div>
            <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Environment</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>



                 {Environment.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent5 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick5(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}

            </div>

            <div class="bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap"  style={{marginTop: 30 + 'px'}} bis_skin_checked="1">
            <p class="font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4">Effects</p>
            </div>

            <div className='text-white justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>


                 {Effects.map((item, index) => (
                <div
                    key={index}
                    className={`w-[190px] border-2 ${
                        activeComponent6 === index ? 'text-red-600 border-red-600' : 'border-zinc-600'
                    } hover:text-red-600 hover:border-red-600 rounded-full mt-3 hover:cursor-pointer`}
                    onClick={() => handleClick6(index)}
                >
                    <p className='font-semibold text-2xl py-2 px-4 text-center'>{item.name}</p>
                </div>
            ))}

            </div>
        <SearchExtension />

        <div style={{width: "100%"}}>
            <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                {generatedImage && <img src={generatedImage} alt="Generated" className="generated-image mt-5" />}
            </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        <button className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center' onClick={generateimage}>
            Generate Image
        </button>
    </div>
</>

    )
}

export default Simplified