import React from 'react'
import DiscoverImagesBox from '../../components/DiscoverImagesBox'

const Gallery = () => {
    return (
        <>
            <div className='lg:px-20 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans'>
                <h1 className='text-3xl font-semibold text-center'>Gallery</h1>
                <DiscoverImagesBox />
            </div>
        </>
    )
}

export default Gallery
