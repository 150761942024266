import React, { useState } from 'react'
import { img1 } from '../../../assets'
import { VscHeartFilled } from 'react-icons/vsc'
import DiscoverImagesBox from '../../components/DiscoverImagesBox';
const promptData = [
    { name: 'PROMPT', description: 'Beautiful tits, horny, porn shot, unique appearance, stunningly beautiful, feminine form, suntanned, film photography, analog photography, film grain, extreme detail, 8k, ultra hd, Steve McCurry, hyperrealism, trending on flikr, polished, beautiful, radiant, synesthesia, vibrant, photorealistic, backlight, hair light, 8k ultra hd, unreal engine 5,' },
    { name: 'FACE PROMPT', description: 'Beautiful tits, horny, porn shot, unique appearance' },
    { name: 'EXCLUDE', description: 'unique appearance' },
    { name: 'EXTENSIONS', description: 'horny, porn shot, unique appearance' },
    { name: 'OTHER DETAILS', description: 'backlight, hair light, 8k ultra hd, unreal engine 5,' },
];

const ImageView = () => {

    const [selectedName, setSelectedName] = useState('PROMPT');

    const selectedDescription = promptData.find(item => item.name === selectedName)?.description;

    return (
        <>
            <div className='2xl:px-20 lg:px-10 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans'>

                <div className='flex flex-wrap justify-center gap-7'>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-3'>
                            <p className='lg:text-2xl font-medium'>Created by </p><VscHeartFilled color='red' className='lg:text-2xl' /><p className='lg:text-2xl font-light text-yellow-500'>@gagan</p>
                        </div>
                        <img src={img1} alt="img1" className='w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl' />
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-3'>
                            <VscHeartFilled color='#696969' className='lg:text-2xl' /><p className='w-7 lg:w-14 lg:text-2xl font-medium text-stone-500 text-left'>8</p><p className='lg:text-2xl font-medium text-stone-500'>DOWNLOAD</p>
                        </div>
                        <div className="md:w-[700px] lg:w-[900px] lg:h-[500px] xl:w-[800px] xl:h-[600px] 2xl:w-[966px] 2xl:h-[621px]  border-2 border-zinc-600 bg-[#6161614A] rounded-xl py-3 px-5">
                            <div className='flex flex-wrap justify-start gap-2 md:gap-4 lg:gap-7 w-full'>
                                {promptData.map(item => (
                                    <p
                                        key={item.name}
                                        className={`cursor-pointer ${selectedName === item.name ? 'text-red-600' : ''} md:text-xl font-semibold`}
                                        onClick={() => setSelectedName(item.name)}
                                    >
                                        {item.name}
                                    </p>
                                ))}
                            </div>
                            <div className="mt-5 text-white md:text-[23px] font-normal">
                                {selectedDescription ? selectedDescription : 'No description available'}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='border-t border-zinc-600 my-9'></div>

                <div className='flex items-center gap-6'>
                    <div className='bg-red-700 w-5 h-5 rounded-full'></div><p className='text-lg lg:text-2xl font-light text-yellow-500'>@aj_2508</p><p className='text-xl text-stone-600 font-medium'>8 hours ego</p>
                </div>

                <p className='text-lg lg:text-2xl font-medium mt-1 lg:mt-6'>What a fucking girl greater than 3</p>
                <p className='text-3xl lg:text-4xl font-medium mt-4 lg:mt-[75px]'>Comment</p>
                <div className='bg-zinc-300 rounded-xl mt-1 lg:mt-2 px-5 py-3 text-black text-lg lg:text-2xl font-light w-full h-48'>
                    <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder='Leave a comment' />
                </div>
                <button className='bg-red-800 w-40 lg:w-80 text-center py-2 rounded-full text-lg lg:text-2xl mt-3 lg:mt-4'>SUBMIT</button>

                <div className='border-t border-zinc-600 my-4 lg:my-9'></div>

                <p className='text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center'>You Might Also Enjoy</p>
                <DiscoverImagesBox />
            </div>
        </>
    )
}

export default ImageView