import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Layout = ({ children }) => (
    <div>
        <header>
            <Header />
        </header>
        <div>
            <aside>
                <Sidebar />
            </aside>
            <div className="main-container float-start w-full md:ps-48 lg:ps-80">
                {children}
            </div>
        </div>
    </div>
);

export default Layout;
