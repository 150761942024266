import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserTable from '../../../components/UserTable'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const InactiveUser = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/addUser')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User List' placename='Search User' btnname='Add User' btnfunction={handleBlog} />
                <UserTable />
            </div>
        </>
    )
}

export default InactiveUser