import React from 'react'

const ImgDetailOnhover = () => {
    return (
        <>
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-4 opacity-0 hover:opacity-100 transition-opacity duration-700">
                <p className="font-bold">@gagan</p>
                <p className='leading-4 my-2'>a stunning asian, brown hair, ponytail, eyes closed, in a swimming pool, luxurious environment</p>
                <p className=" text-red-600 font-bold">AI MODEL</p>
                <p className='font-bold'>ANIME HD</p>
            </div>
        </>
    )
}

export default ImgDetailOnhover