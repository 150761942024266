import React from 'react'

const Dashbord = () => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
        // Redirect to login or show an error
        console.error('No auth token found. Please log in.');
        return;
    }

    const data = [{ totImg: 'Total Images', imginNumber: '16085', increaserate: '+32.40%', incresMonth: 'Increased last month', }, { totImg: '', imginNumber: '', increaserate: '', incresMonth: '', }, { totImg: '', imginNumber: '', increaserate: '', incresMonth: '', }, { totImg: '', imginNumber: '', increaserate: '', incresMonth: '', },]
    return (
        <>
            <div className='bg-white py-7 lg:p-7 font-josefin-sans'>
                <div className='flex justify-center gap-8 flex-wrap'>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className='w-[320px] h-[235px] border-2 border-zinc-300 rounded-lg py-8 px-7'>
                                    <p className='text-[22px] font-semibold text-zinc-500'>{item.totImg}</p>
                                    <p className='text-[30px] font-semibold text-black pt-5 pb-10'>{item.imginNumber}</p>
                                    <p className='text-lg font-medium text-zinc-500'><span className='text-[20px] font-semibold text-green-600 pr-3'>{item.increaserate}</span>{item.incresMonth}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Dashbord
