import React from 'react'
import { img1, img10, img2, img3, img4, img5, img6, img7, img8, img9 } from '../../assets'
import { useNavigate } from 'react-router-dom'
import ImgDetailOnhoverGenration from './ImgDetailOnhoverGenration'

const GenerationImagesBox = () => {

    const navigate = useNavigate()

    const handleImageView = () => {
        navigate('/dashboard/ImageView')
    }

    return (
        <>
            <div className='w-full flex flex-wrap gap-3 justify-center mt-5 lg:mt-14'>
                <div className='flex flex-wrap gap-3 flex-col justify-center'>
                    <div className='flex flex-wrap gap-3 justify-center'>
                        <div className='flex flex-col gap-3 justify-center'>
                            <div className="relative w-[370px] h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img1}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhoverGenration />
                            </div>
                            <div className="relative w-[370px] h-[265px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img5}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhoverGenration />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 justify-center'>
                            <div className="relative w-[370px] h-[265px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img2}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhoverGenration />
                            </div>
                            <div className="relative w-[370px] h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                                <img
                                    src={img4}
                                    alt="Hover Effect"
                                    className="h-full w-full object-cover rounded-xl "
                                />
                                <ImgDetailOnhoverGenration />
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[370px] md:w-[750px] h-[244px] hover:cursor-pointer" onClick={handleImageView} >
                        <img
                            src={img6}
                            alt="Hover Effect"
                            className="h-full w-full object-cover rounded-xl "
                        />
                        <ImgDetailOnhoverGenration />
                    </div>

                </div>
                <div className='flex flex-wrap gap-3 justify-center'>
                    <div className='flex flex-col gap-3 justify-center'>
                        <div className="relative w-[370px] h-[520px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img3}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhoverGenration />
                        </div>
                        <div className="relative w-[370px] h-[524px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img8}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhoverGenration />
                        </div>
                    </div>
                    <div className='flex flex-col gap-3 justify-center'>
                        <div className="relative w-[370px] h-[260px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img10}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhoverGenration />
                        </div>
                        <div className="relative w-[370px] h-[520px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img7}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhoverGenration />
                        </div>
                        <div className="relative w-[370px] h-[253px] hover:cursor-pointer" onClick={handleImageView} >
                            <img
                                src={img9}
                                alt="Hover Effect"
                                className="h-full w-full object-cover rounded-xl "
                            />
                            <ImgDetailOnhoverGenration />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default GenerationImagesBox