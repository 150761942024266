import React, { useState } from 'react'
import InputBox from '../../../components/InputBox';
import { uplodeFile } from '../../../../assets';
import AddtagMetaKey from '../../../components/AddtagMetaKey';

const SiteDetail = () => {

    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
        } else {
            setFileName('');
        }
    };

    return (
        <>
            <p className='text-2xl font-semibold mt-10 mb-6'>Site Detail</p>
            <InputBox placholderName='Select Discount type' title='Meta Title' textType='text' />
            <label className='text-zinc-500 font-semibold text-[22px]'>Meta Description</label>
            <textarea name="Meta Description" id="" className='border border-zinc-300 p-3 focus:outline-none w-full h-28 mt-3 rounded-lg text-lg font-medium'></textarea>
            <label className='text-zinc-500 font-semibold text-[22px]'>Meta Keywords</label>
            <AddtagMetaKey />

            <p className='text-zinc-500 font-semibold text-[22px] my-4'>Upload Sitemap</p>
            <div className='flex items-center gap-2'>
                <div className="bg-white px-2 py-3 border border-zinc-300 rounded-lg flex justify-between items-center ">
                    <label htmlFor="file-upload" className="flex items-center cursor-pointer">
                        <input
                            id="file-upload"
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                        <input
                            type="text"
                            className="border-none outline-none px-5 xl:w-[640px] text-xl"
                            placeholder='Upload File'
                            readOnly
                            value={fileName}
                        />
                        <img src={uplodeFile} alt="Upload File" className="ml-2 pr-5" />
                    </label>
                </div>
                <div className="ml-4">
                    <span className="font-bold">File Name :</span> {fileName}
                </div>
            </div>

            <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save</button>
        </>
    )
}

export default SiteDetail
