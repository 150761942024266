import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DeleteImg, Dote, rename, singleBlogImg } from '../../../../assets';
import DeletePopup from '../../../components/DeletePopup';
import PagenameSearchBox from '../../../components/PagenameSearchBox';
import axios from 'axios';

const BASE_URL = "https://api.eromantic.ai/api/";

const SingleBlog = () => {
    const navigate = useNavigate();
    const { blogId } = useParams();
    const location = useLocation();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [blog, setBlog] = useState(location.state?.blog || null);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = async () => {
        try {
        const authToken = localStorage.getItem('authToken');

           await axios.delete(`${BASE_URL}delete_blog/${blogId}/`, {
            headers: {
                'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
            }
        });
            setIsPopupOpen(false);
            navigate('/admin/blogList');
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };

    useEffect(() => {
        if (!blog) {
            const fetchBlog = async () => {
                try {
                 const authToken = localStorage.getItem('authToken');
                    const response = await axios.get(`${BASE_URL}details_blog/${blogId}/`, {
                headers: {
                'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
                }
            });
                    setBlog(response.data);
                } catch (error) {
                    console.error('Error fetching blog:', error);
                }
            };

            fetchBlog();
        }
    }, [blog, blogId]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const handleBlog = () => {
        navigate('/admin/blogList');
    };

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Blog List' titlesecond='Single Blog' imgDote={Dote} btnname='Back to blog list' btnfunction={handleBlog} />
                <div className='border border-zinc-300 rounded-lg md:p-5 lg:p-10 mb-10'>
                    <div className='flex justify-end gap-7'>
                        <button>
                            <img src={rename} alt="remane" onClick={() => navigate(`/admin/EditBlog/${blogId}`)} />
                        </button>
                        <button>
                            <img src={DeleteImg} alt="remane" onClick={togglePopup} />
                        </button>
                    </div>
                    <p className='md:text-2xl lg:text-4xl font-semibold text-black'>{blog.title}</p>
                    <div className='py-8'>
                        <img src={blog.image} alt="blog" className='w-[1239px] h-[525px] m-auto rounded-xl object-cover' />
                    </div>


                   <div className='text-left p-[14px]'>
                    <div
                        className='blogsdata text-black pb-3'
                        dangerouslySetInnerHTML={{ __html: blog.description }}
                    />

                </div>
                </div>
                <div className="border border-zinc-300 rounded-lg md:p-5 lg:p-10 mb-10">
                    <p className='md:text-xl lg:text-2xl font-semibold text-black mb-[20px]'>Meta Details</p>
                    Title: <p className='md:text-lg lg:text-[28px] font-normal text-zinc-600 pb-3 '>{blog.meta_title}</p>
                    Description: <div
                        className='md:text-lg lg:text-[30px] font-semibold text-black pb-3'
                        dangerouslySetInnerHTML={{ __html: blog.meta_description }}
                    />
                    Keywords: <div
                        className='md:text-lg lg:text-[30px] font-semibold text-black pb-3'
                        dangerouslySetInnerHTML={{ __html: blog.meta_keywords }}
                    />
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />
            )}
        </>
    );
};

export default SingleBlog;

