import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, Dote, rename, singleBlogImg } from '../../../../assets'
import DeletePopup from '../../../components/DeletePopup'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const SingleHelpBlog = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/helpBlog')
    }

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = () => {
        // setIsPopupOpen(false);
    };

    return (
        <>
            <div className='bg-white px-7 font-josefin-sans'>
                <PagenameSearchBox title='Help Blog List' titlesecond='Single help blog' imgDote={Dote} btnname='Back to blog list' btnfunction={handleBlog} />
                <div className='border border-zinc-300 rounded-lg md:p-5 lg:p-10 mb-10'>
                    <div className='flex justify-end gap-7'>
                        <button>
                            <img src={rename} alt="remane" />
                        </button>
                        <button>
                            <img src={DeleteImg} alt="remane" onClick={togglePopup} />
                        </button>
                    </div>
                    <p className='md:text-2xl lg:text-4xl font-semibold text-black'>How to pay with Cryptocurrency</p>
                    <p className='md:text-lg lg:text-3xl font-normal text-zinc-600 mt-[20px]'>Learn How to pay with Cryptocurrency and purchase a plan on SEDUCED.AI</p>
                    <div className='py-8'>
                        <img src={singleBlogImg} alt="blog" className='w-[1239px] h-[525px] m-auto rounded-xl' />
                    </div>
                    <p className='md:text-lg lg:text-3xl font-semibold text-black pb-3'>Here's a step-by-step guide on how to buy a plan on SEDUCED.AI using cryptocurrency:</p>
                    <p className='md:text-lg lg:text-3xl font-semibold text-black pb-3'>
                        1. Navigate to the EROMANTIC.AI plans page by entering the following URL in your browser: https://www.eromantic.ai/plans</p>
                    <p className='md:text-lg lg:text-3xl font-semibold text-black pb-3'>2. On the plans page, choose the cryptocurrency payment option.</p>
                    <div className='py-8'>
                        <img src={singleBlogImg} alt="blog" className='w-[1239px] h-[525px] m-auto rounded-xl' />
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />
            )}

        </>
    )
}

export default SingleHelpBlog
