import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Togal from './Togal'
import { DeleteImg, rename } from '../../assets'
import DeletePopup from './DeletePopup'

const HelpBlogListCard = ({ img, title, subtext }) => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/singleHelpBlog')
    }

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = () => {
        // setIsPopupOpen(false);
    };

    return (
        <>
            <div className='border-2 border-zinc-300 rounded-lg w-[307px] font-josefin-sans'>
                <div className='flex justify-between p-3 items-center'>
                    <Togal />
                    <div className='flex justify-center gap-3 items-center'>
                        <p className='text-zinc-600 font-medium hover:cursor-pointer'>Edit</p>
                        <img src={rename} alt="deleteImg" className='hover:cursor-pointer' />
                        <img src={DeleteImg} alt="deleteImg" className='hover:cursor-pointer' onClick={togglePopup} />
                    </div>
                </div>
                <div onClick={handleBlog} className='hover:cursor-pointer'>
                    <img src={img} alt="blogListCard" className='w-[307px] h-[173px]' />
                    <div className='text-left p-[14px]'>
                        <p className='text-black text-lg font-semibold'>{title}</p>
                        <p className='text-zinc-600 text-[12px] font-medium pt-[5px] pb-[12px] '>Create at 22/05/2024</p>
                        <p className='text-black text-sm font-normal leading-tight'>{subtext}</p>
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />
            )}
        </>
    )
}

export default HelpBlogListCard