import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputBox from '../../../components/InputBox';
import { imgSelector } from '../../../../assets';
import Dropdown from '../../../components/Dropdown';
import PagenameSearchBox from '../../../components/PagenameSearchBox';

const AddCategory = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [parentCategory, setParentCategory] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories from the backend
        const fetchCategories = async () => {
            try {
                 const authToken = localStorage.getItem('authToken');
                 const response = await axios.get('https://api.eromantic.ai/api/show_blog_categories/', {
                    headers: {
                        'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
                    }
                });
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (parentCategory) {
            formData.append('parent_category', parentCategory);
        }
        if (selectedImage) {
            formData.append('image', selectedImage);
        }

        try {
            const response = await axios.post('https://api.eromantic.ai/api/add_category/', formData, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.status === 201 || response.status === 200) {
                navigate('/admin/category');
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
    }
};

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Category List' btnname='Back to Category List' btnfunction={() => navigate('/admin/category')} />
                <InputBox title='Category Title' placholderName='Category' value={title} onChange={(e) => setTitle(e.target.value)} />
                <InputBox title='Category Description' placholderName='Description' value={description} onChange={(e) => setDescription(e.target.value)} />

                <div className='pb-6 flex flex-col'>
                    <label className='text-zinc-500 pb-3 font-semibold text-[22px]'>Category Image</label>
                    <div className="flex items-center justify-center w-80">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border border-zinc-300 rounded-lg cursor-pointer bg-white px-2 py-3"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                {selectedImage ? (
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        className="w-full h-32 object-cover mb-4"
                                    />
                                ) : (
                                    <img src={imgSelector} alt="imgSelector" className='w-10 lg:w-20' />
                                )}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                                <p className="mt-6 text-base text-stone-400">
                                    {selectedImage ? 'Change Featured Image' : 'Add Featured Image'}
                                </p>
                            </div>
                        </label>
                    </div>
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 text-lg font-semibold text-white p-4' onClick={handleSubmit}>Add Category</button>
            </div>
        </>
    );
}

export default AddCategory;
