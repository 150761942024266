import React from 'react'
import InputBox from '../../../components/InputBox'
import ReactQuill from 'react-quill'

const Plans = () => {
    return (
        <>
            <div>
                <div className='w-full mt-7 font-josefin-sans'>
                    <div className='w-[48%]'>
                        <InputBox placholderName='Bronze' title='Bronze' textType='number' />
                    </div>
                    <ReactQuill theme="snow" className='h-40 md:mb-20 lg:mb-14 mt-6' />
                    <div className='w-[48%]'>
                        <InputBox placholderName='Gold' title='Gold' textType='number' />
                    </div>
                    <ReactQuill theme="snow" className='h-40 md:mb-20 lg:mb-14 mt-6' /><div className='w-[48%]'>
                        <InputBox placholderName='Platinum' title='Platinum' textType='number' />
                    </div>
                    <ReactQuill theme="snow" className='h-40 md:mb-20 lg:mb-14 mt-6' />
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save Changes</button>
                </div>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4'>Reset</button>
                </div>
            </div>
        </>
    )
}

export default Plans
