import React, { useState } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';

const Tooltip = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative flex items-center">
            <button
                type="button"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <AiFillQuestionCircle color='#616161' size={22} />
            </button>
            {showTooltip && (
                <div className="absolute z-10 inline-block border border-stone-300 px-3 w-fit py-2 text-sm font-medium text-black bg-white rounded-lg shadow-sm " style={{ top: '50%', transform: 'translateY(-50%)', left: '100%', marginLeft: '10px' }}>
                    {text}
                    {/* <div className="tooltip-arrow" data-popper-arrow></div> */}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
