import React from 'react'

const PagenameSearchBox = ({ title, titlesecond, placename, btnname, btnfunction, imgDote }) => {

    return (
        <>
            <div className='flex justify-between items-center pt-9 pb-8'>
                <div className='flex gap-5'>
                    <div className='flex items-center gap-3'>
                        <p className='text-[28px] font-semibold text-gray-900'>{title}</p>
                        {titlesecond &&
                            <>
                                <img src={imgDote} alt="dote" />
                                <p className='text-[28px] font-semibold text-gray-900'>{titlesecond}</p>
                            </>
                        }
                    </div>
                    {
                        placename && <div className='bg-white ms-10 px-2 py-1 w-[282px] border border-zinc-300 rounded-lg flex items-center'>
                            <input type="text" className='focus:outline-none text-lg w-full' placeholder={placename} />
                        </div>
                    }
                </div>
                <button onClick={btnfunction} className='rounded-lg text-lg bg-red-800 hover:bg-red-700 text-white py-2 px-4  min-w-44'>{btnname}</button>
            </div>
        </>
    )
}

export default PagenameSearchBox
