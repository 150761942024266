import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Header from "../components/Header";

function CookiePolicy() {
  return (
    <div>
      <div className="bg-black ">
        <div className="container mx-auto">
          <div className="p-10">
            <p className="text-white text-center py-10 md:text-[50px] text-[20px] mt-[50px]">
              Cookie Policy
            </p>

            <h1 className="text-white text-[25px] mb-[-35px]"> Introduction</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                This Cookie Policy explains how cookies and similar technologies are used on our website. By using our website, you consent to the use of cookies in accordance with this Cookie Policy.
              </p>  

              <h1 className="text-white text-[25px] mb-[-35px]"> What are cookies?</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                Cookies are small text files that are stored on your device (computer, tablet, mobile phone) when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the website owners.
              </p>

              <h1 className="text-white text-[25px] mb-[-35px]"> Types of cookies we use:</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                1. Essential cookies: These cookies are necessary for the website to function properly. They enable core functionality such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies.

                2. Performance cookies: These cookies collect information about how visitors use our website, such as which pages are visited most often, and if visitors receive error messages from certain pages. These cookies do not collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how the website works.

                3. Functionality cookies: These cookies allow the website to remember choices you make (such as your content preferences, username, language, or the region you are in) and provide enhanced, more personalized features. For example, we may display different content based on your chosen content preferences. Cookies may also be stored to not display the same warning messages twice.
                </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Google Analytics</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                We use Google Analytics, a web analytics service provided by Google, Inc. ('Google'). Google Analytics uses cookies to help analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Managing cookies</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer, and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site, and some services and functionalities may not work.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Changes to this Cookie Policy</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                We may update this Cookie Policy from time to time in order to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Contact us</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                If you have any questions about our use of cookies or this Cookie Policy, please contact us at support@eromantic.ai
              </p>


             

            <div>
             
            </div>
            <div className=" max-w-full mt-2  border-t border-white">
              <div className="flex justify-between  gap-4 text-white pt-5 pb-5">
                <Link to="/blogs" className="text-[14px] lg:text-lg">
                  Blogs
                </Link>
                <Link to="/terms-of-service" className="text-[14px] lg:text-lg">
                  Terms of Service
                </Link>
                <Link to="/privacy-policy" className="text-[14px] lg:text-lg">
                  Privacy Policy
                </Link>
                <Link to="/cookie-policy" className="text-[14px] lg:text-lg">
                  Cookie Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
