import React from 'react'
import { useNavigate } from 'react-router-dom'
import HelpBlogListCard from '../../../components/HelpBlogListCard'
import { blogListCard } from '../../../../assets'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const HelpBlog = () => {
    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/createHelpBlog')
    }

    const cradData = [{ img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' }, { img: blogListCard, title: 'How to pay with Cryptocurrency', subtext: 'Learn How to pay with Cryptocurrency and purchase a plan on EROMANTIC.AI' },]

    return (
        <>
            <div className='bg-white mb-10 px-7 font-josefin-sans'>
                <PagenameSearchBox title='Help Blog List' placename='Search Blog' btnname='CREATE HELP BLOG' btnfunction={handleBlog} />
                <div className='flex flex-wrap justify-center gap-8 pt-8'>
                    {
                        cradData.map((item, index) => {
                            return <HelpBlogListCard key={index} img={item.img} title={item.title} subtext={item.subtext} />
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default HelpBlog