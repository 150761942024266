import React, { useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';

const AddtagMetaKey = () => {
    const [tags, setTags] = useState(['Keywords']);
    const [input, setInput] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && input.trim() !== '') {
            setTags([...tags, input.trim()]);
            setInput('');
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div className="flex flex-col border border-stone-300 px-4 rounded-lg w-full mt-3 font-josefin-sans">
            <div className="flex flex-wrap items-center">
                {tags.map((tag, index) => (
                    <div key={index} className='flex items-center bg-red-800 text-white rounded-xl text-xl ps-3 pr-1 py-[6px] m-1'>
                        <span className="mr-2">{tag}</span>
                        <IoCloseCircle size={30} className='hover:cursor-pointer' color='white' onClick={() => removeTag(index)} />
                    </div>
                ))}
                <input
                    type="text"
                    className="border-none outline-none p-2 text-lg m-1"
                    onKeyDown={handleKeyDown}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                />
            </div>
        </div>
    );
};

export default AddtagMetaKey;
