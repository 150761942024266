import React from 'react'
import { img1, img3, img4, img7 } from '../../../assets'

const Saved = () => {
    const imgdata = [
        { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, },
    ]

    return (
        <>
            <div className='flex flex-wrap justify-center gap-4'>
                {
                    imgdata.map((data, index) => {
                        return (
                            <div key={index} className='relative w-72 h-80 xl:w-[383px] xl:h-[524px] lg:mt-7'>
                                <img src={data.images} alt="img1" className='w-72 h-80 xl:w-[383px] xl:h-[524px] rounded-xl' />
                                <button className='bg-red-800 text-white font-medium rounded-full absolute px-5 py-2 bottom-10  ml-[100px] xl:ml-[140px]'>
                                    Unsave
                                </button>
                            </div>
                        )
                    })
                }


            </div>
        </>
    )
}

export default Saved
