import React from 'react'
import Togal from './Togal'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, eye, rename, tableUser1, tableUser2 } from '../../assets'


const UserTable = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/userProfile')
    }

    return (
        <>
            <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                <table className="w-full text-left  text-gray-500 border border-stone-300">
                    <thead className="text-xl text-black  bg-stone-300">
                        <tr>
                            <th scope="col" className=" px-6 py-3">
                                User
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Generations
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Plan
                            </th>
                            <th scope="col" className=" px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className=" px-6 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white font-normal text-black text-xl border-b border-stone-300">
                            <tr>
                                <td
                                    className="px-6 flex items-center py-4 text-gray-900 whitespace-nowrap "
                                >
                                    <img src={tableUser2} alt="user2" onClick={handleBlog} className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                    <td className=" whitespace-nowrap ">                                <td className="px-4 whitespace-nowrap ">Gagandeep singh</td>
                                    </td>
                                </td>
                            </tr>
                            <td className="px-6 py-4 whitespace-nowrap ">gagan.pyt@gmail.com</td>
                            <td className="px-6 py-4 whitespace-nowrap ">21929</td>
                            <td className="px-6 py-4  whitespace-nowrap ">
                                <div className='rounded-full border text-base w-20 py-1 border-[#FFD700] bg-[#FFD7004A] text-[#FFD700]'>
                                    <p className='text-center'>Gold</p>
                                </div>
                            </td>
                            <td className="px-6 py-4"><Togal /></td>
                            <td className="px-6 py-4">
                                <div className='flex gap-5 justify-center items-center'>
                                    <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                </div>
                            </td>
                        </tr>
                        <tr className="bg-white font-normal text-black text-xl border-b border-stone-300">
                            <tr>
                                <td
                                    className="px-6 flex items-center py-4 text-gray-900 whitespace-nowrap "
                                >
                                    <img src={tableUser1} alt="user2" onClick={handleBlog} className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                    <td className=" whitespace-nowrap ">                                <td className="px-4 whitespace-nowrap ">Gagandeep singh</td>
                                    </td>
                                </td>
                            </tr>
                            <td className="px-6 py-4 whitespace-nowrap ">gagan.pyt@gmail.com</td>
                            <td className="px-6 py-4 whitespace-nowrap ">21929</td>
                            <td className="px-6 py-4  whitespace-nowrap ">
                                <div className='rounded-full border text-base w-20 py-1 border-amber-600 bg-[#CD7F324A] text-amber-600'>
                                    <p className='text-center'>Bronze</p>
                                </div>
                            </td>
                            <td className="px-6 py-4"><Togal /></td>
                            <td className="px-6 py-4">
                                <div className='flex gap-5 justify-center items-center'>
                                    <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                    <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                    <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UserTable
