const DeletePopup = ({ togglePopup, handleDelete }) => {

    return (
        <>
            <div className="h-[810px] md:h-[965px] lg:h-[100vh] fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-10">
                <div className="bg-white py-10 rounded-lg shadow-lg w-[550px]">
                    <p className="mb-4 font-semibold text-xl text-center">Do you want to delete the blog?</p>
                    <div className="flex justify-center gap-10 mt-10">
                        <button
                            onClick={togglePopup}
                            className="w-44 px-4 py-[6px] font-semibold text-black bg-gray-300 rounded-lg hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDelete}
                            className="w-44 px-4 py-[6px] font-semibold text-white bg-red-800 rounded-lg hover:bg-red-700"
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeletePopup
