import React from 'react'
import Recreateimage from '../../components/Recreateimage'
import SelectAIModel from '../../components/SelectAIModel'
import SearchExtension from '../../components/SearchExtension'
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox'
import FooterMakeAI from '../../components/FooterMakeAI'

const AdvancePromopt = () => {
    return (
        <>
            <p className='pt-5 text-xl xl:text-[25px] font-medium'>The advanced mode is intended for those who can prompt effectively. We suggest exploring the showcased section and replicating the prompts from the generations you find appealing. Additionally, make sure to retain the other settings, including extensions, to achieve the desired results.</p>

            <Recreateimage />

            <SelectAIModel />

            <SearchExtension />

            <UseShortWordsInputBox name='Imagine (prompt)' plachold='a stunning blonde girl, large breasts, in a space station, pink thighighs, ponytail' />
            <UseShortWordsInputBox name='Other (use short words and commas)' plachold='e.g. smiling, sunny weather' />
            <UseShortWordsInputBox name='Face prompt (optional)' plachold='e.g. beutiful' />

            <FooterMakeAI />
        </>
    )
}

export default AdvancePromopt