import React from 'react';
import DashboardHeader from '../components/DashboardHeader';

const LayoutFrontend = ({ children }) => (
    <div>
        <header>
            <DashboardHeader />
        </header>
        <div>
            <div className="main-container float-start w-full">
                {children}
            </div>
        </div>
    </div>
);

export default LayoutFrontend;
