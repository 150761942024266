import React from 'react'

const EasyTrackingBox = ({ name, text }) => {
    return (
        <>
            <div className='w-full md:w-[630px] 2xl:w-[730px] md:h-[180px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl py-4 px-2 md:px-10'>
                <p className='text-2xl'>{name}</p>
                <p className='text-lg pt-2 tracking-wider'>{text}</p>
            </div>
        </>
    )
}

export default EasyTrackingBox