import React from 'react';

const data = [
    { title: 'EROMANTIC.AI', name: 'Normal', namecolor: '' },
    { title: 'EROMANTIC.AI', name: 'Normal', namecolor: 'yellow' },
    { title: 'EROMANTIC.AI', name: 'Normal', namecolor: 'pink' },
    { title: 'EROMANTIC.AI', name: 'Normal', namecolor: 'blue' },
];

const Modality = () => {
    return (
        <>
            <div className="px-5 lg:px-20 pb-52 bg-black pt-10 text-white font-josefin-sans">
                <h1 className="text-white text-2xl md:text-3xl lg:text-4xl font-semibold mb-4 lg:mb-10 text-center">
                    Choose modality
                </h1>
                <div className='flex justify-center mb-10 xl:mb-5'>
                    <div className='rounded-full xl:w-[85%] bg-[#FF001F3B] border-2 border-red-600 text-red-500 px-5 py-3'>
                        <p className='text-center text-lg lg:text-xl font-medium w-full'>Please select the model that help to get best results in terms of accuracy</p>
                    </div>
                </div>

                <div className='flex flex-wrap justify-center gap-12 2xl:gap-36 items-center mt-20'>
                    <div className='flex xl:w-[820px] 2xl:w-[850px] flex-wrap gap-5 2xl:gap-10 justify-center items-center'>
                        {data.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="border-2 hover:border-red-600 border-zinc-600 hover:bg-[#FF001F3B] bg-[#6161614A] p-6 rounded-lg flex flex-col items-center justify-center text-white w-60 h-32 sm:w-72 sm:h-40 lg:w-[330px] lg:h-[200px] xl:w-[400px] xl:h-[230px]"
                                >
                                    <p className="text-xl lg:text-3xl xl:text-4xl font-semibold">EROMANTIC.AI</p>
                                    <p className={`text-lg lg:text-2xl xl:text-3xl font-normal mt-2 ${item.namecolor === 'yellow' ? 'text-yellow-500' : item.namecolor === 'pink' ? 'text-fuchsia-500' : item.namecolor === 'blue' ? 'text-cyan-400' : 'text-red-600'}`}
                                    >
                                        {item.name}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="border-2 rounded-xl flex justify-center items-center border-zinc-600 w-full md:w-[600px] lg:w-[700px] h-80 xl:w-[400px] xl:h-[480px] bg-[#6161614A]">
                        <p className="text-xl lg:text-3xl font-semibold">Includes all content.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modality;
