import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, Downlode, Logo, rename } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const CreateInvoice = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/invoice')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Invoice' btnname='Back to Invoice list' btnfunction={handleBlog} />
                <div className='border border-stone-300 rounded-lg w-full p-9'>
                    <div className='flex justify-end gap-7'>
                        <img src={Downlode} alt="downlode" />
                        <div>
                            <img src={rename} alt="rename" className='h-7' />
                        </div>
                        <div>
                            <img src={DeleteImg} alt="deleteImg" className='h-7' />
                        </div>
                    </div>
                    <div className='md:gap-8 xl:gap-0 py-10 xl:p-10 flex justify-between items-end'>
                        <div className='w-[370px] text-stone-500 text-left font-semibold text-xl '>
                            <img src={Logo} alt="logo" />
                            <p className='mt-3'>Office 149, 450 South Brand Brooklyn
                                San Diego County, CA 91905, USA</p>
                            <p> +1 (123) 456 7891, +44 (876) 543 2198</p>
                        </div>
                        <div className='w-fit text-stone-500 text-left font-semibold '>
                            <p className='text-left font-semibold text-2xl mb-4'>Invoice #ET001</p>
                            <p className='text-left font-semibold text-xl '>Date Issues: April 25, 2021</p>
                            <p className='text-left font-semibold text-xl '>Date Due: May 25, 2021</p>
                        </div>
                    </div>
                    <div className='md:gap-8 xl:gap-0 py-10 xl:p-10 pt-5 pb-14 flex justify-between items-start '>
                        <div className='text-black text-left w-[260px]'>
                            <p className='text-lef font-normal text-xl mb-4'>Invoice To:</p>
                            <p className='text-left text-xl font-light '>Thomas shelby</p>
                            <p className='text-left text-xl font-light '>Shelby Company Limited Small Heath, B10 0HF, UK718-986-6062</p>
                            <p className='text-left text-xl font-light '>peakyFBlinders@gmail.com</p>
                        </div>
                        <div className='text-black text-left w-fit'>
                            <p className='text-lef font-normal text-xl mb-4'>Payment Method:</p>
                            <p className='text-left text-xl font-light '>Visa ending **** 4242</p>
                            <p className='text-left text-xl font-light '>jsmith@email.com</p>
                        </div>
                    </div>
                    <div className='border border-[#a5a3a35b] rounded-lg w-full'>
                        <div className='bg-stone-300 text-xl font-medium justify-between px-10 py-4 rounded-tl-lg rounded-tr-lg flex items-center'>
                            <p>Plan</p>
                            <p>Price</p>
                        </div>
                        <div className='px-10 w-full py-4 flex justify-between gap-5 xl:gap-24'>
                            <div className='ps-5 text-stone-500 text-left font-semibold text-base xl:text-xl'>
                                <p className='text-left font-semibold text-2xl'>Gold</p>
                                <ul>
                                    <li className='list-disc pt-3'>Generate stunning images: Start with 2500 credits (roughly 2500 images) and upgrade as needed.</li>
                                    <li className='list-disc pt-3'>Edit and Enhance: Upscale images (2x-4x resolution), adjust clothing and objects, add elements, and expand image dimensions.</li>
                                    <li className='list-disc pt-3'>Seamless Image Management: Save and re-use your favorite creations.</li>
                                    <li className='list-disc pt-3'>Privacy Control: Keep your generations private.</li>
                                    <li className='list-disc pt-3'>Discover and Integrate: Explore and download fresh content with the option to select up to 4 model extensions.</li>
                                    <li className='list-disc pt-3'>Replicate and Refine: Upload an image to replicate a specific face or pose for tailored results.Adjust Extension Influence: Control the weight of your selected extensions for fine-tuned output.</li>
                                    <li className='list-disc pt-3'>Go Beyond Still Images: Experiment with video generation capabilities.</li>
                                    <li className='list-disc pt-3'>Restore Credits: Restore up to 2500 credits* for easy recovery.</li>
                                    <li className='list-disc pt-3'>Faster Workflow: Queue up to 150 images for streamlined generation.</li>
                                    <li className='list-disc pt-3'>First In Line: Priority over Silver and Bronze Members</li>
                                </ul>
                            </div>
                            <div className='w-fit'>
                                <p className='text-left text-base xl:text-xl font-light '>$125/-Monthly</p>
                            </div>
                        </div>
                    </div>
                    <div className='px-0 lg:px-5 xl:px-20 pt-5 flex justify-between items-start border-b pb-20 border-stone-300'>
                        <div className='text-black text-left w-fit'>
                            <p className='text-left text-xl font-light'>Thanks for choosing us</p>
                        </div>
                        <div className='text-black  w-fit text-xl  pb-3'>
                            <div className='flex justify-between gap-10 text-left pb-2'>
                                <div className='flex flex-col justify-center gap-1'>
                                    <p className='text-left font-medium'>Subtotal:</p>
                                    <p className='text-left font-medium'>Tax:</p>
                                    <p className='text-left font-medium'>Subtotal:</p>
                                </div>
                                <div className='flex flex-col justify-center gap-1'>
                                    <p className='text-left font-light'>$00.00</p>
                                    <p className='text-left font-light'>$50.00</p>
                                    <p className='text-left font-light'>$204.25</p>
                                </div>
                            </div>
                            <div className='flex justify-between border-t border-stone-300 text-xl gap-10 pt-2'>
                                <p className='font-medium'>Total:</p>
                                <p className='font-light'>$154.25</p>
                            </div>
                        </div>
                    </div>
                    <p className='font-medium text-lg text-center text-stone-500 mt-3'>Note: It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You! </p>
                </div>
            </div>
        </>
    )
}

export default CreateInvoice