import React from 'react'
import { goldE, info } from '../../assets'

const FooterMakeAI = () => {
    return (
        <>
            <div>
                <div className='flex flex-wrap justify-between pt-20'>
                    <div className='bg-black flex flex-col gap-5 lg:gap-12 text-white font-josefin-sans'>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Steps</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-semibold text-red-500'>Medium</p>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-light'>High Very High Extremely High</p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Magic</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-semibold text-red-500'>None</p>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-light'>Normal High</p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Variations</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <input type="number" className='focus:outline-none bg-zinc-300 rounded-xl px-5 py-1 mt-3 w-52 text-black text-2xl' />
                        </div>
                        <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
                            <input
                                type="radio"
                                className='w-7 h-7'
                            />
                            <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make Private</p>
                        </div>
                    </div>
                    <div className='bg-black flex flex-col gap-5 lg:gap-12 text-white font-josefin-sans'>
                        <div className='mt-3'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make AI listen</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-semibold text-red-500'>Let it be creative</p>
                                <p className='text-base md:text-lg lg:text-xl xl:text-2xl font-light'>Normal A lot</p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Aspect ratio <span className='text-base md:text-lg lg:text-xl xl:text-2xl'>(square, horizontal, vertical)</span></p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 text-center mt-2'>
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-12 text-black text-2xl' />
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-24 text-black text-2xl' />
                                <input type="number" className='focus:outline-none text-center bg-zinc-300 rounded-xl py-1 w-12 text-black text-2xl' />
                            </div>
                        </div>
                    </div>
                </div>
                <button className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
                    Generate for 1.00
                    <img src={goldE} alt="goldE" />
                </button>
            </div>
        </>
    )
}

export default FooterMakeAI