import React, {useState, useEffect, useRef} from 'react';
import { IconCominsoon, Logo, Rectangle329, twitterX, instaIcon } from '../../../assets';
import ComingSoonMobile from './ComingSoonMobile';
import Header from "../Header";
import NotifyModal from "./NotifyModal";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BackGround from "./BackGround";

const ComingSoon = () => {

     const [isOpen, setIsOpen] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please Enter Valid Email"),
  });
  const [width, setWidth] = useState(0);
  console.log("🚀 ~ Home ~ width:", width);
  const selectRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (selectRef.current) {
        const width = selectRef.current.clientWidth - 7000;
        setWidth(width);
      }
    };

    // Initial width calculation
    handleResize();

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Call your POST API here
      const response = await fetch(
        "https://api.eromantic.ai/user/subscribe_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body : JSON.stringify(values),
        }
      );


      resetForm();
      if (response.ok) {
        setIsOpen(true);
        console.log("API call successful");
      } else {

        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
    }
  };


    return (
        <>
            <div className='bg-white py-4 flex justify-center items-center'>
                <img src={Logo} alt="logo" className='w-56 md:w-72' />
            </div>

            <div className='hidden md:block comingsoon relative font-josefin-sans'>

                <img src={Rectangle329} alt='Rectangle329' className='comming-img absolute top-0 left-0 w-full object-cover imgbackgroung' />


                <div className='absolute top-0 left-0 w-full bg-black opacity-55'></div>

                <div className='flex justify-center text-center'>
                    <div className='relative z-10 text-white pt-10'>
                        <div className="flex items-center justify-center text-center my-4 gap-3">
                            <hr className="border-2 w-[42px] lg:w-[62px] " />
                            <p className=" text-[30px] lg:text-[40px] font-medium text-white mt-5">Coming Soon</p>
                            <hr className="border-2 w-[42px] lg:w-[62px]" />
                        </div>
                        <div className='text-[51px] lg:text-[61px] xl:text-[71px] font-bold leading-tight mt-5'>
                            <p>Get Notified</p>
                            <p> When We Launch</p>
                        </div>
                        <div className='flex items-center justify-center mt-10'>
                            <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="sm:bg-primary border-2 w-full border-white pl-[30px] lg:pl-[56px] py-2 pr-2 rounded-full flex items-center " style={{width:"702px"}}>
                        <Field
                          className="text-white sm:text-[22px] flex-1  border-white font-medium placeholder:text-white bg-transparent sm:bg-primary border-0 outline-none focus:outline-none ring-0 "
                          placeholder="Enter your email address"
                          name="email"
                        />
                        <button
                          type="submit"
                          className="text-black font-medium lg:text-[20px] bg-white rounded-full xl:px-[30px] xl:py-[10px] px-[12px] py-[8px] hidden sm:block text-nowrap "
                        >
                          Notify Me
                        </button>
                      </div>
                      <div className="pt-2 pl-2">
                        <p className="!text-white">
                          <ErrorMessage name="email" />
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="text-black font-medium sm:text-[22px] bg-white rounded-full sm:px-[35px] sm:py-[14px] px-[12px] py-[5px] sm:hidden block my-2 w-full"
                      >
                        Notify Me
                      </button>
                    </Form>
                  </Formik>

                        </div>
                        <p className='text-[23px] my-8'>* Get Discounts & Other Goodies For Joining The Waitlist :)</p>
                        <div className='flex justify-center items-center'>
                             <div className='border-[2px] border-white rounded-xl px-10 py-3 flex justify-content-between items-center gap-4'>
                                <h4 className='text-3xl' style={{marginTop: '10px'}}>Follow Us</h4>
                                <a className='p-1 border-[1px] border-white rounded-full' href='https://x.com/eromanticai' target='_blank'>
                                    <img src={twitterX}
                                    style={{height: '40px', width: '40px'}}
                                    />
                                </a>
                                <a className='p-1 border-[1px] border-white rounded-full' href='https://www.instagram.com/eromantic.ai/' target='_blank'>
                                    <img src={instaIcon}
                                    style={{height: '40px', width: '40px'}}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className='mt-72 lg:mt-52 flex flex-col justify-center items-center' style={{ marginTop: "10rem", marginBottom: "5rem"}}>
                            <hr className='border-1 border-white w-[641px] ' />
                            <div className='flex text-center justify-center gap-10 text-white text-[22px] font-semibold my-5'>
                                <a class="text-[14px] lg:text-lg" href="/blogs">Blogs</a>
                                <a class="text-[14px] lg:text-lg" href="/terms-of-service">Terms of Service</a>
                                <a class="text-[14px] lg:text-lg" href="/privacy-policy">Privacy Policy</a>
                                <a class="text-[14px] lg:text-lg" href="/cookie-policy">Cookie Policy</a>

                            </div>
                        </div>
                    </div>
                </div>
                <NotifyModal isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>

            <div className='block md:hidden'>
                <ComingSoonMobile />
            </div>
        </>
    );
}

export default ComingSoon;
