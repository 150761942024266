import React from 'react'
import { useNavigate } from 'react-router-dom'
import { remaneUserProfile, tableUser1 } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const UserProfile = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/allUser')
    }

    return (
        <>
            <div className='bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Profile' btnname='Back to user list' btnfunction={handleBlog} />
                <div className='rounded-lg bg-red-800 w-full pb-16 mt-12'>
                    <div className='flex justify-end pt-7 pr-7'>
                        <img src={remaneUserProfile} alt="rename" className='w-10 h-10' />
                    </div>
                    <div className='flex items-center px-5 xl:px-16 pb-8 gap-14 border-b border-white'>
                        <div >
                            <img src={tableUser1} alt="user1" className='w-40 h-40 rounded-full' />
                        </div>
                        <div>
                            <p className='text-white text-4xl font-bold'>Gagandeep Singh</p>
                            <p className='text-white text-3xl font-normal pt-[10px]'>@gags2508</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile