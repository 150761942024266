import React, { useState } from 'react'
import InputBox from '../../../components/InputBox'
import Tooltip from '../../../components/Tooltip';
import CouponTable from '../../../components/CouponTable';

const Coupon = () => {
    const [activeTab, setActiveTab] = useState('General');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    const restrictionData = [
        { label: 'Minimum spend', placehold: 'No Minimum', type: 'number' }, { label: 'Maximum spend', placehold: 'No Maximum', type: 'number' },
        { label: 'Allowed emails', type: 'email' },
    ]

    const usageLimitData = [
        { label: 'Usage limit per coupon', placehold: 'Usage limit per coupon', type: 'number' }, { label: 'Usage limit per user', placehold: 'Usage limit per user', type: 'number' },
    ]

    return (
        <>
            <div className='w-full mt-7 font-josefin-sans'>
                <p className='text-2xl font-semibold my-10'>Add Coupon</p>
                <div className='flex justify-between border border-stone-300 rounded-md h-[500px]'>
                    <div className='w-[37%] lg:w-[30%] xl:w-[20%] 2xl:w-[15%] rounded-tl-md flex flex-col '>
                        <button
                            className={`text-left ps-8 rounded-tl-md border-b border-r py-3 border-stone-300 w-full text-lg font-semibold ${activeTab === 'General' ? 'bg-zinc-500 text-white' : 'bg-white'} hover:bg-zinc-500 hover:text-white`}
                            onClick={() => setActiveTab('General')}
                        >
                            General
                        </button>
                        <button
                            className={`text-left ps-8 border-b border-r py-3 border-stone-300 w-full text-lg font-semibold ${activeTab === 'Usage Restriction' ? 'bg-zinc-500 text-white' : 'bg-white'} hover:bg-zinc-500 hover:text-white`}
                            onClick={() => setActiveTab('Usage Restriction')}
                        >
                            Usage Restriction
                        </button>
                        <button
                            className={`text-left rounded-bl-md ps-8 border-b border-r py-3 border-stone-300 w-full text-lg font-semibold ${activeTab === 'Usage Limit' ? 'bg-zinc-500 text-white' : 'bg-white'} hover:bg-zinc-500 hover:text-white`}
                            onClick={() => setActiveTab('Usage Limit')}
                        >
                            Usage Limit
                        </button>
                    </div>
                    <div className='w-[50%] lg:w-[55%] xl:w-[50%] mt-5'>
                        {activeTab === 'General' ? (
                            <div>
                                <label className='text-zinc-500 font-semibold text-[22px]'>Discount Type</label>
                                <div className="relative inline-block text-left w-[100%] my-3">
                                    <div>
                                        <button
                                            type="button"
                                            className="bg-white w-[100%] text-gray-400 px-2 py-3 border border-zinc-300 rounded-lg"
                                            onClick={toggleDropdown}
                                        >
                                            <div className={`flex justify-between text-xl items-center px-5 ${selectedOption ? 'text-black' : 'text-zinc-300'}`}>
                                                {selectedOption || 'Select Categories here...'}
                                                <svg
                                                    className="ml-2 h-5 w-5 inline-block"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </button>
                                    </div>

                                    {isOpen && (
                                        <div className="absolute w-full z-10 mt-2  bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                <button
                                                    className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                                    onClick={() => handleOptionClick('Option 1')}
                                                >
                                                    Option 1
                                                </button>
                                                <button
                                                    className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                                    onClick={() => handleOptionClick('Option 2')}
                                                >
                                                    Option 2
                                                </button>
                                                <button
                                                    className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                                    onClick={() => handleOptionClick('Option 3')}
                                                >
                                                    Option 3
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='w-full flex gap-3 items-center'>
                                    <div className='w-[98%]'>
                                        <InputBox title='Coupon amount' placholderName='25' textType='number' />
                                    </div>
                                    <div className='w-[2%]'>
                                        <Tooltip text='Coupon amount' />
                                    </div>
                                </div>
                                <div className='w-full flex gap-3 items-center'>
                                    <div className='w-[98%]'>
                                        <InputBox title='Coupon expiry date' placholderName='25' textType='date' />
                                    </div>
                                    <div className='w-[2%]'>
                                        <Tooltip text='Date here..' />
                                    </div>
                                </div>

                            </div>

                        ) : activeTab === 'Usage Restriction' ? (
                            <div>
                                {
                                    restrictionData.map((item, index) => {
                                        return (
                                            <div key={index} className='w-full flex gap-3 items-center'>
                                                <div className='w-[98%]'>
                                                    <InputBox title={item.label} placholderName={item.placehold} textType={item.type} />
                                                </div>
                                                <div className='w-[2%]'>
                                                    <Tooltip text='Coupon amount' />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <p className='text-stone-500 text-lg font-medium pb-3'>Individual use only</p>
                                <div className='flex justify-start items-center gap-3'>
                                    <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
                                    <p className='text-lg text-stone-500 font-medium leading-none'>Check this box if the coupon cannot be used in conjunction with other coupons.</p>
                                </div>
                            </div>
                        ) : activeTab === 'Usage Limit' ? (
                            <div>
                                {
                                    usageLimitData.map((data, index) => {
                                        return (
                                            <div key={index} className='w-full flex gap-3 items-center'>
                                                <div className='w-[98%]'>
                                                    <InputBox title={data.label} placholderName={data.placehold} textType={data.type} />
                                                </div>
                                                <div className='w-[2%]'>
                                                    <Tooltip text='Coupon amount' />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null}
                    </div>
                    <div className='w-[5%] lg:[5%] xl:w-[20%] 2xl:w-[20%]'></div>
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save Coupon</button>
                <div className='flex justify-start gap-32 items-center pt-9 px-7'>
                    <div className='flex gap-1 lg:gap-5'>
                        <p className='text-[28px] font-semibold text-black'>Coupon List</p>
                        <div className='bg-white ms-10 px-2 py-1 border border-zinc-300 rounded-lg flex items-center'>
                            <input type="text" className='focus:outline-none ' placeholder='Search Coupon' />
                        </div>
                    </div>
                </div>

                <CouponTable />
            </div>
        </>
    )
}

export default Coupon
