import React from 'react'
import { deleteHoverImg } from '../../assets'
import { FaDroplet } from 'react-icons/fa6'

const ImgDetailOnhoverGenration = () => {
    return (
        <>
            <div className="absolute inset-0 flex flex-col justify-between opacity-0 hover:opacity-100 transition-opacity duration-700">
                <div className="flex justify-between p-4">
                    <button className='bg-red-800 px-4 py-1 rounded-2xl flex items-center justify-center'>
                        <img src={deleteHoverImg} alt="deleteHoverImg" />
                    </button>
                    <div className='flex gap-4'>
                        <button className='bg-zinc-950 px-3 py-1 rounded-2xl flex items-center justify-center'>
                            <FaDroplet color='#616161' className="text-2xl" />
                        </button>
                        <button className="bg-red-800 text-white font-medium rounded-2xl px-3 py-2">
                            Download
                        </button>
                    </div>
                </div>
                <div className="bg-black bg-opacity-70 text-white p-4">
                    <p className="font-bold">@gagan</p>
                    <p className="leading-4 my-2">a stunning asian, brown hair, ponytail, eyes closed, in a swimming pool, luxurious environment</p>
                    <p className="text-red-600 font-bold">AI MODEL</p>
                    <p className="font-bold">ANIME HD</p>
                </div>
            </div>
        </>
    )
}

export default ImgDetailOnhoverGenration
